define("ember-nrg-ui/components/nrg-search/component", ["exports", "ember-nrg-ui/components/nrg-search/template", "ember-nrg-ui/mixins/validation", "ember-keyboard", "ember-concurrency"], function (_exports, _template, _validation, _emberKeyboard, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validation.default, _emberKeyboard.EKMixin, _emberKeyboard.EKFirstResponderOnFocusMixin, {
    layout: _template.default,
    classNames: ['ui', 'category', 'search', 'focus', 'box', 'inlined'],
    classNameBindings: ['fluid', '_loading:loading'],
    focused: false,
    fluid: true,
    placeholder: 'Search',
    items: null,
    loading: false,
    _loading: Ember.computed.or('loading', 'updateDisplayValue.isRunning'),
    displayLabel: 'header',
    notLoading: Ember.computed.not('_loading'),
    minCharacters: 1,
    activeItem: -1,
    selected: null,

    didInsertElement() {
      this._super(...arguments);

      this.createClickHandler();
    },

    willDestroyElement() {
      this._super(...arguments);

      this.removeWindowClickListener();
    },

    addWindowClickListener() {
      document.addEventListener('click', this._clickHandler, true);
    },

    removeWindowClickListener() {
      document.removeEventListener('click', this._clickHandler, true);
    },

    createClickHandler() {
      this.set('_clickHandler', evt => {
        if (!this.element.contains(evt.target)) {
          this.set('focused', false);
          this.set('activeItem', -1);
        }

        return true;
      });
    },

    itemsObserver: Ember.observer('items', function () {
      this.set('activeItem', -1);
      this.set('receivedResults', true);
    }),
    selectedObserver: Ember.observer('selected', 'selected.isLoading', 'selected.isFulfilled', function () {
      this.get('updateDisplayValue').perform();
    }),
    showResultsObserver: Ember.observer('showResults', function () {
      Ember.run.next(() => {
        if (this.get('showResults')) {
          this.addWindowClickListener();
        } else {
          this.removeWindowClickListener();
        }
      });
    }),
    updateDisplayValue: (0, _emberConcurrency.task)(function* () {
      const selected = yield this.get('selected');
      const displayLabel = Ember.get(selected || {}, this.get('displayLabel'));
      this.set('searchString', displayLabel || '');
    }).on('init').restartable(),
    canPerformSearch: Ember.computed('minCharacters', 'searchString', function () {
      return this.get('searchString.length') >= this.get('minCharacters');
    }),
    showResults: Ember.computed.and('focused', 'canPerformSearch', 'notLoading', 'receivedResults'),
    searchTimeout: 300,
    throttleQuery: (0, _emberConcurrency.task)(function* (searchString) {
      yield (0, _emberConcurrency.timeout)(this.get('searchTimeout'));
      this.query(searchString);
      this.set('focused', true);
    }).restartable(),
    moveUp: Ember.on((0, _emberKeyboard.keyDown)('ArrowUp'), function (evt) {
      const activeItem = this.get('activeItem');

      if (this.get('showResults')) {
        evt.preventDefault();
        evt.stopPropagation();

        if (activeItem !== 0) {
          this.decrementProperty('activeItem');
        }
      }
    }),
    moveDown: Ember.on((0, _emberKeyboard.keyDown)('ArrowDown'), function (evt) {
      const activeItem = this.get('activeItem');

      if (this.get('showResults')) {
        evt.preventDefault();
        evt.stopPropagation();

        if (activeItem < this.get('items.length') - 1) {
          this.incrementProperty('activeItem');
        }
      }
    }),
    enter: Ember.on((0, _emberKeyboard.keyDown)('Enter'), function (evt) {
      this.send('select', evt);
    }),
    actions: {
      inputClicked() {
        this.set('focused', true);
      },

      query(searchString) {
        this.set('receivedResults', false);
        this.get('throttleQuery').perform(searchString);
      },

      select(evt, item) {
        if (!this.get('showResults')) {
          return true;
        }

        if (evt) {
          evt.preventDefault();
          evt.stopPropagation();
          item = this.get('items')[this.get('activeItem')];
        }

        this.select(item);
        this.set('selected', item);
        this.set('focused', false);
        Ember.run.next(() => {
          const input = this.element.querySelector('input');

          if (input) {
            input.focus();
          }
        });
      }

    }
  });

  _exports.default = _default;
});