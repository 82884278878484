define("ember-nrg-ui/components/nrg-lightbox-thumbnail/component", ["exports", "ember-nrg-ui/components/nrg-lightbox-thumbnail/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    lightboxService: Ember.inject.service('lightbox'),
    detail: null,
    thumbnailId: Ember.computed(function () {
      return Ember.guidFor(this);
    }),

    didInsertElement() {
      this._super(...arguments);

      const item = this.getProperties('thumbnailId', 'photo', 'detail');
      this.get('lightboxService').add(item);
    },

    didDestroyElement() {
      const thumbnailId = this.get('thumbnailId');
      this.get('lightboxService').remove(thumbnailId);

      this._super(...arguments);
    },

    setDetail(_ref) {
      let {
        innerHTML
      } = _ref;
      this.set('detail', innerHTML);
      const thumbnailId = this.get('thumbnailId');
      this.get('lightboxService').updateDetail(thumbnailId, innerHTML);
    },

    openLightbox() {
      const thumbnailId = this.get('thumbnailId');
      this.get('lightboxService').selectAndOpen(thumbnailId);
    }

  });

  _exports.default = _default;
});