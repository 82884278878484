define("ember-nrg-ui/components/nrg-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1S77FS4a",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,12],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,11],null,[[\"list\",\"header\",\"items\",\"create-button\"],[[32,0],[30,[36,9],[\"nrg-list/header\"],[[\"filterChanged\",\"searchChanged\"],[[30,[36,7],[[32,0],[35,10]],null],[30,[36,7],[[32,0],[32,0,[\"searchChanged\"]]],null]]]],[30,[36,9],[\"nrg-list/items\"],[[\"isSelectable\",\"selectionType\",\"pageMeta\",\"pageSizes\",\"selectedPageSize\",\"loading\",\"items\",\"itemClicked\",\"changePage\",\"groupHeaderHandler\"],[[35,0],[35,1],[35,2],[35,3],[35,4],[35,5],[35,6],[30,[36,7],[[32,0],[32,0,[\"onItemSelect\"]]],null],[30,[36,7],[[32,0],[32,0,[\"onChangePage\"]]],null],[35,8]]]],[30,[36,9],[\"nrg-button\"],[[\"class\",\"icon\"],[\"ui circular icon big primary bottom right floating button\",\"plus\"]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"nrg-list/items\",[],[[\"@isSelectable\",\"@selectionType\",\"@pageMeta\",\"@pageSizes\",\"@selectedPageSize\",\"@loading\",\"@items\",\"@itemClicked\",\"@changePage\",\"@groupHeaderHandler\"],[[34,0],[34,1],[34,2],[34,3],[34,4],[34,5],[34,6],[30,[36,7],[[32,0],[32,0,[\"onItemSelect\"]]],null],[30,[36,7],[[32,0],[32,0,[\"onChangePage\"]]],null],[34,8]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"isSelectable\",\"selectionType\",\"pageMeta\",\"pageSizes\",\"selectedPageSize\",\"loading\",\"items\",\"action\",\"groupHeaderHandler\",\"component\",\"filterChanged\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-list/template.hbs"
    }
  });

  _exports.default = _default;
});