define("ember-nrg-ui/components/nrg-keyboard-shortcut-modal/component", ["exports", "ember-keyboard", "ember-nrg-ui/mixins/global-keyboard-shortcut", "ember-nrg-ui/components/nrg-keyboard-shortcut-modal/special-characters", "ember-nrg-ui/components/nrg-keyboard-shortcut-modal/template"], function (_exports, _emberKeyboard, _globalKeyboardShortcut, _specialCharacters, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_globalKeyboardShortcut.default, {
    layout: _template.default,
    keyboardService: Ember.inject.service('keyboard-shortcut'),
    isOpen: Ember.computed.alias('keyboardService.modalIsOpen'),
    keyboardShortcuts: [{
      key: 'Slash',
      shft: true,
      priority: 40,
      actionName: 'openKeyboardHelp',
      description: 'Show Keyboard Help'
    }],
    shortcuts: Ember.computed.alias('keyboardService.shortcuts'),
    mappedShortcuts: Ember.computed('shortcuts.[]', function () {
      return this.get('shortcuts').map(shortcut => {
        const modKeys = [];

        if (shortcut.ctrl) {
          modKeys.push('Ctrl');
        }

        if (shortcut.alt) {
          modKeys.push('Alt');
        }

        if (shortcut.shft) {
          modKeys.push('Shft');
        }

        const keys = [];

        if (shortcut.lastCode) {
          keys.push(this.getCharacter((0, _emberKeyboard.getKeyCode)(shortcut.lastCode), shortcut.shft));
        }

        keys.push(this.getCharacter((0, _emberKeyboard.getKeyCode)(shortcut.code), shortcut.shft));
        return {
          modKeys,
          keys,
          header: shortcut.header,
          description: shortcut.description
        };
      });
    }),
    headers: Ember.computed.mapBy('mappedShortcuts', 'header'),
    uniqHeaders: Ember.computed.uniq('headers'),
    shortcutSegments: Ember.computed('uniqHeaders', 'mappedShortcuts', function () {
      const mappedShortcuts = this.get('mappedShortcuts');
      const headers = this.get('uniqHeaders');
      return headers.map(header => {
        const shortcuts = Ember.A(mappedShortcuts).filterBy('header', header);
        return {
          header,
          shortcuts
        };
      });
    }),
    segmentSort: ['header:asc'],
    sortedSegments: Ember.computed.sort('shortcutSegments', 'segmentSort'),

    getCharacter(code, shift) {
      let key = '';
      const conversionType = shift ? _specialCharacters.default.shift : _specialCharacters.default.default;

      if (conversionType[code] !== undefined) {
        key = conversionType[code];
      } else {
        key = String.fromCharCode(code);
      }

      return key.toUpperCase();
    },

    actions: {
      openKeyboardHelp() {
        this.set('isOpen', true);
      }

    }
  });

  _exports.default = _default;
});