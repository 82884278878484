define("ember-modal-dialog/components/tether-dialog", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-decorators/component", "ember-modal-dialog/components/basic-dialog", "ember-modal-dialog/templates/components/tether-dialog"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _component, _basicDialog, _tetherDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  let TetherDialog = (_dec = (0, _component.layout)(_tetherDialog.default), _dec2 = Ember.computed('targetAttachment'), _dec3 = Ember.computed, _dec(_class = (_class2 = class TetherDialog extends _basicDialog.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "targetAttachment", null);
      (0, _defineProperty2.default)(this, "attachment", null);
      (0, _defineProperty2.default)(this, "tetherTarget", null);
    }

    init() {
      super.init(...arguments);

      this._ensureAttachments();
    }

    get targetAttachmentClass() {
      let targetAttachment = this.targetAttachment || ''; // Convert tether-styled values like 'middle right' to 'right'

      targetAttachment = targetAttachment.split(' ').slice(-1)[0];
      return `ember-modal-dialog-target-attachment-${Ember.String.dasherize(targetAttachment)} emd-target-attachment-${Ember.String.dasherize(targetAttachment)}`;
    }

    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);

      this._ensureAttachments();
    }

    // element, css selector, view instance, 'viewport', or 'scroll-handle'
    get tetherClassPrefix() {
      return 'ember-tether';
    }

    set tetherClassPrefix(val) {
      if (val) {
        return val;
      }

      return 'ember-tether';
    } // offset - passed in
    // targetOffset - passed in
    // targetModifier - passed in


    _ensureAttachments() {
      if (!this.attachment) {
        Ember.set(this, 'attachment', 'middle center');
      }

      if (!this.targetAttachment) {
        Ember.set(this, 'targetAttachment', 'middle center');
      }
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "targetAttachmentClass", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "targetAttachmentClass"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "tetherClassPrefix", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "tetherClassPrefix"), _class2.prototype)), _class2)) || _class);
  _exports.default = TetherDialog;
});