define("ember-nrg-ui/components/nrg-text-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "axO6hETN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,14],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,13],null,[[\"input\"],[[30,[36,12],[\"nrg-text-field/trim-input\"],[[\"type\",\"min\",\"max\",\"step\",\"value\",\"name\",\"placeholder\",\"autocomplete\",\"autocapitalize\",\"disabled\",\"maxLength\",\"onFocus\",\"onBlur\",\"onChange\"],[[35,0],[35,1],[35,2],[35,3],[35,4],[35,5],[35,6],[35,7],[35,8],[35,9],[35,10],[30,[36,11],[[32,0],[32,0,[\"onFocus\"]]],null],[30,[36,11],[[32,0],[32,0,[\"onBlur\"]]],null],[30,[36,11],[[32,0],[32,0,[\"onChange\"]]],null]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"nrg-text-field/trim-input\",[],[[\"@type\",\"@min\",\"@max\",\"@step\",\"@value\",\"@name\",\"@placeholder\",\"@autocomplete\",\"@autocapitalize\",\"@disabled\",\"@maxLength\",\"@onFocus\",\"@onBlur\",\"@onChange\"],[[34,0],[34,1],[34,2],[34,3],[34,4],[34,5],[34,6],[34,7],[34,8],[34,9],[34,10],[30,[36,11],[[32,0],[32,0,[\"onFocus\"]]],null],[30,[36,11],[[32,0],[32,0,[\"onBlur\"]]],null],[30,[36,11],[[32,0],[32,0,[\"onChange\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"type\",\"min\",\"max\",\"step\",\"value\",\"name\",\"placeholder\",\"autocomplete\",\"autocapitalize\",\"disabled\",\"maxLength\",\"action\",\"component\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-text-field/template.hbs"
    }
  });

  _exports.default = _default;
});