define("ember-nrg-ui/components/nrg-radio/component", ["exports", "ember-nrg-ui/components/nrg-radio/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    readonly: false,

    onClick(value) {
      if (this.disabled || this.readOnly) {
        return;
      }

      this.set('selectedValue', value);
      this.onChange(value);
    },

    onChange(value) {
      this.sendAction('action', value);
    }

  });

  _exports.default = _default;
});