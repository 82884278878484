define("ember-nrg-ui/breakpoints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.widescreenMonitorBreakpoint = _exports.tabletBreakpoint = _exports.mobileBreakpoint = _exports.largeMonitorBreakpoint = _exports.default = _exports.computerBreakpoint = void 0;
  const mobileBreakpoint = 320;
  _exports.mobileBreakpoint = mobileBreakpoint;
  const tabletBreakpoint = 768;
  _exports.tabletBreakpoint = tabletBreakpoint;
  const computerBreakpoint = 992;
  _exports.computerBreakpoint = computerBreakpoint;
  const largeMonitorBreakpoint = 1200;
  _exports.largeMonitorBreakpoint = largeMonitorBreakpoint;
  const widescreenMonitorBreakpoint = 1920;
  _exports.widescreenMonitorBreakpoint = widescreenMonitorBreakpoint;
  var _default = {
    smallMobile: `(max-width: ${mobileBreakpoint - 1}px)`,
    mobile: `(min-width: ${mobileBreakpoint}px) and (max-width: ${tabletBreakpoint - 1}px)`,
    tablet: `(min-width: ${tabletBreakpoint}px) and (max-width: ${computerBreakpoint - 1}px)`,
    computer: `(min-width: ${computerBreakpoint}px) and (max-width: ${largeMonitorBreakpoint - 1}px)`,
    largeMonitor: `(min-width: ${largeMonitorBreakpoint}px) and (max-width: ${widescreenMonitorBreakpoint - 1}px)`,
    widescreenMonitor: `(min-width: ${widescreenMonitorBreakpoint}px)`
  };
  _exports.default = _default;
});