define("ember-nrg-ui/components/nrg-popup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zHYA1cIt",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"popup-anchor\"],[12],[2,\"\\n  \"],[18,1,[[30,[36,6],null,[[\"popup\"],[[30,[36,5],[\"nrg-popup/popup\"],[[\"isOpen\",\"target\",\"onMouseEnter\",\"onMouseLeave\"],[[35,4],[35,3],[30,[36,1],[[32,0],[35,2]],null],[30,[36,1],[[32,0],[35,0]],null]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"onMouseLeave\",\"action\",\"onMouseEnter\",\"target\",\"isOpen\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-popup/template.hbs"
    }
  });

  _exports.default = _default;
});