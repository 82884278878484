define("ember-nrg-ui/components/nrg-text-field/trim-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S4KMSg2s",
    "block": "{\"symbols\":[],\"statements\":[[8,\"input\",[],[[\"@type\",\"@min\",\"@max\",\"@step\",\"@value\",\"@name\",\"@placeholder\",\"@autocomplete\",\"@autocapitalize\",\"@disabled\",\"@maxlength\",\"@readonly\",\"@focus-out\",\"@focus-in\",\"@key-up\"],[[34,0],[34,1],[34,2],[34,3],[34,4],[34,5],[34,6],[30,[36,8],[[35,7],\"on\",\"off\"],null],[34,9],[34,10],[34,11],[34,12],[30,[36,13],[[32,0],[32,0,[\"onBlur\"]]],null],[30,[36,13],[[32,0],[32,0,[\"onFocus\"]]],null],[30,[36,13],[[32,0],[32,0,[\"onChange\"]]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"type\",\"min\",\"max\",\"step\",\"_value\",\"name\",\"placeholder\",\"autocomplete\",\"if\",\"autocapitalize\",\"disabled\",\"maxLength\",\"readonly\",\"action\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-text-field/trim-input/template.hbs"
    }
  });

  _exports.default = _default;
});