define("ember-nrg-ui/components/nrg-context-menu/component", ["exports", "ember-nrg-ui/components/nrg-context-menu/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    contextService: Ember.inject.service('context-menu'),
    options: Ember.computed.alias('contextService.contextItems'),
    disabled: Ember.computed.alias('contextService.disabled'),

    itemSelected(selected) {
      selected.client.send(selected.actionName, selected);
    },

    itemChecked(selected, checked) {
      selected.client.send(selected.actionName, selected, checked);
    }

  });

  _exports.default = _default;
});