define("ember-nrg-ui/components/nrg-home-cards/home-card/component", ["exports", "ember-nrg-ui/components/nrg-home-cards/home-card/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['card', 'home-card'],
    classNameBindings: ['actionCard', 'visible::is-visually-hidden'],
    tagName: 'a',
    router: Ember.inject.service(),
    visible: Ember.computed('currentUser.roles', 'role', function () {
      if (!this.role) {
        return true;
      }

      const roles = Array.isArray(this.role) ? this.role : [this.role];
      const needsAllRoles = this.needsAllRoles;
      const currentUserContent = this.get('currentUser.content');

      if (needsAllRoles) {
        return roles.every(role => currentUserContent.hasRole(role));
      }

      return roles.some(role => currentUserContent.hasRole(role));
    }),

    click() {
      const route = this.route;
      const routeModel = this.routeModel;
      const url = this.url;

      if (route && routeModel) {
        this.router.transitionTo(route, routeModel);
      } else if (route) {
        this.router.transitionTo(route);
      } else if (url) {
        // https://mathiasbynens.github.io/rel-noopener/
        const newWindow = window.open();
        newWindow.opener = null;
        newWindow.location = url;
      } else {
        this.sendAction();
      }
    }

  });

  _exports.default = _default;
});