define("ember-nrg-ui/components/nrg-lightbox-thumbnail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J9P4cQbr",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"img\"],[24,\"role\",\"button\"],[24,0,\"ui image photo-thumbnail\"],[16,\"src\",[34,2,[\"url\"]]],[16,\"alt\",[34,2,[\"altText\"]]],[4,[38,1],[[32,0],[35,3]],null],[12],[13],[2,\"\\n\"],[6,[37,4],[[27,[32,1]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"nrg-render-template-block\",[],[[\"@templateRendered\"],[[30,[36,1],[[32,0],[35,0]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"setDetail\",\"action\",\"photo\",\"openLightbox\",\"if\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-lightbox-thumbnail/template.hbs"
    }
  });

  _exports.default = _default;
});