define("ember-nrg-ui/components/nrg-form-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5HhCU0+X",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,2],null,[[\"form\",\"field\",\"submit-button\",\"form-link\",\"form-action\"],[[32,0],[30,[36,0],[\"nrg-form-field\"],[[\"form\"],[[32,0]]]],[30,[36,0],[\"nrg-button\"],[[\"type\",\"class\",\"text\",\"disabled\"],[\"submit\",\"primary\",\"Submit\",[35,1]]]],[30,[36,0],[\"link-to\"],[[\"class\"],[\"item form-link\"]]],[30,[36,0],[\"nrg-form-action\"],null]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"disabled\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-form-container/template.hbs"
    }
  });

  _exports.default = _default;
});