define("ember-nrg-ui/components/nrg-toast-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wardf6b3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"toast\"],[[30,[36,0],[\"nrg-toast\"],null]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-toast-container/template.hbs"
    }
  });

  _exports.default = _default;
});