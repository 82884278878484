define("ember-nrg-ui/components/nrg-home-cards/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hwU0dC2p",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,2],null,[[\"home-card\"],[[30,[36,1],[\"nrg-home-cards/home-card\"],[[\"actionCard\"],[[35,0]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"actionCard\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-home-cards/template.hbs"
    }
  });

  _exports.default = _default;
});