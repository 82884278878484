define("ember-nrg-ui/components/nrg-form-action/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fi2gCGkr",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[34,0]],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"text\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-form-action/template.hbs"
    }
  });

  _exports.default = _default;
});