define("ember-nrg-ui/instance-initializers/breakpoints-override", ["exports", "ember-nrg-ui/breakpoints"], function (_exports, _breakpoints) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(application) {
    application.unregister('breakpoints:main');
    application.register('breakpoints:main', _breakpoints.default);
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});