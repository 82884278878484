define("ember-nrg-ui/components/nrg-responsive-takeover/component", ["exports", "ember-nrg-ui/mixins/resize", "ember-nrg-ui/components/nrg-responsive-takeover/template"], function (_exports, _resize, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_resize.default, {
    layout: _template.default,
    applicationService: Ember.inject.service('application'),
    responsive: Ember.inject.service('responsive'),
    router: Ember.inject.service('router'),
    isMobileScreen: Ember.computed.alias('responsive.isMobileScreenGroup'),
    title: Ember.computed.reads('applicationService.pageTitle'),
    renderInModal: Ember.computed.and('isMobileScreen', 'shouldTakeOver'),
    renderInPlace: Ember.computed.not('renderInModal'),
    mainContentStyle: Ember.computed('screenHeight', function () {
      return Ember.String.htmlSafe(`height: calc(${this.get('screenHeight')}px - 48px`);
    }),

    onBackArrowClick() {
      (true && !(this.previousRoute) && Ember.assert('You must implment the onBackArrowClick action or provide a previousRoute', this.previousRoute));

      if (this.previousRoute) {
        this.router.transitionTo(this.previousRoute);
      }
    }

  });

  _exports.default = _default;
});