define("ember-ref-bucket/modifiers/create-ref", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "ember-modifier", "ember-ref-bucket/utils/ref", "ember-ref-bucket/utils/prototype-reference"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _emberModifier, _ref, _prototypeReference) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let RefModifier = (_dec = Ember._action, (_class = class RefModifier extends _emberModifier.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "_key", this.name);
      (0, _defineProperty2.default)(this, "_ctx", this.ctx);
      (0, _defineProperty2.default)(this, "defaultMutationObserverOptions", {
        attributes: false,
        characterData: false,
        childList: false,
        subtree: false
      });
      (0, _ref.setGlobalRef)(Ember.getOwner(this));
    } // to minimise overhead, user should be specific about
    // what they want to observe


    markDirty() {
      (0, _ref.bucketFor)(this._ctx).dirtyTrackedCell(this._key);
    }

    cleanMutationObservers() {
      if (this._mutationsObserver) {
        this._mutationsObserver.disconnect();
      }
    }

    cleanResizeObservers() {
      if (this._resizeObserver) {
        this._resizeObserver.unobserve(this.element);
      }
    }

    installMutationObservers() {
      this._mutationsObserver = new MutationObserver(this.markDirty);
      const opts = this.getObserverOptions();
      delete opts.resize;

      if (opts.attributes || opts.characterdata || opts.childlist) {
        // mutations observer throws if observe is attempted
        // with all these options disabled
        this._mutationsObserver.observe(this.element, opts);
      }
    }

    validateTrackedOptions() {
      const args = ['subtree', 'attributes', 'children', 'resize', 'character'];

      if (args.some(name => name in this.args.named)) {
        (true && !(this.isTracked) && Ember.assert(`"ember-ref-modifier", looks like you trying to use {{${this.args.named.debugName}}} without tracked flag or alias, but, with properties, related to tracked modifier (${args.join(', ')})`, this.isTracked));
      }
    }

    getObserverOptions() {
      // to minimise overhead user
      // should be specific about
      // what they want to observe
      let resize = false;
      let subtree = this.defaultMutationObserverOptions.subtree;
      let attributes = this.defaultMutationObserverOptions.attributes;
      let character = this.defaultMutationObserverOptions.characterData;
      let children = this.defaultMutationObserverOptions.childList;

      if ('subtree' in this.args.named) {
        subtree = this.args.named.subtree;
      }

      if ('attributes' in this.args.named) {
        attributes = this.args.named.attributes;
      }

      if ('children' in this.args.named) {
        children = this.args.named.children;
      }

      if ('resize' in this.args.named) {
        resize = this.args.named.resize;
      }

      if ('character' in this.args.named) {
        character = this.args.named.character;
      }

      return {
        subtree,
        attributes,
        childList: children,
        resize,
        characterData: character
      };
    }

    installResizeObservers() {
      this._resizeObserver = new ResizeObserver(this.markDirty);

      this._resizeObserver.observe(this.element);
    }

    didReceiveArguments() {
      (true && !(typeof this.name === 'string' && this.name.length > 0) && Ember.assert(`You must provide string as first positional argument for {{${this.args.named.debugName}}}`, typeof this.name === 'string' && this.name.length > 0));
      this.validateTrackedOptions();
      this.cleanMutationObservers();
      this.cleanResizeObservers();

      if (this.name !== this._key || this._ctx !== this.ctx) {
        (0, _ref.bucketFor)(this._ctx).add(this._key, null);
      }

      this._ctx = this.ctx;
      this._key = this.name;
      (0, _ref.watchFor)(this.name, this.ctx, () => {
        const keys = (0, _prototypeReference.getReferencedKeys)(this.ctx, this.name);
        keys.forEach(keyName => {
          // consume keys with callbacks
          this.ctx[keyName];
        });
      });
      (0, _ref.bucketFor)(this.ctx).add(this.name, this.element);

      if (this.isTracked) {
        this.installMutationObservers();

        if (this.getObserverOptions().resize) {
          this.installResizeObservers();
        }
      }
    }

    get ctx() {
      (true && !(this.args.named.bucket !== null) && Ember.assert(`ember-ref-bucket: You trying to use {{${this.args.named.debugName}}} as local reference for template-only component. Replace it to {{global-ref "${this.args.positional[0]}"}}`, this.args.named.bucket !== null));
      return this.args.named.bucket || Ember.getOwner(this);
    }

    get isTracked() {
      return this.args.named.tracked || false;
    }

    get name() {
      return this.args.positional[0];
    }

    willDestroy() {
      this.cleanMutationObservers();
      this.cleanResizeObservers();
      (0, _ref.getNodeDestructors)(this.element).forEach(cb => cb());
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "markDirty", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "markDirty"), _class.prototype)), _class));
  _exports.default = RefModifier;
});