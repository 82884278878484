define("ember-nrg-ui/components/nrg-modal/component", ["exports", "ember-nrg-ui/components/nrg-modal/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    hasButtons: Ember.computed.or('primaryButton', 'secondaryButton'),
    applicationService: Ember.inject.service('application'),
    modalService: Ember.inject.service('modal'),
    isTesting: Ember.computed.reads('applicationService.isTesting'),
    isOpen: false,
    hasMovedDom: false,
    dismissable: true,
    basic: false,
    sidebar: false,
    lightbox: false,
    scrolling: Ember.computed.and('notMasterDetail', 'notLightbox', 'notSidebar', 'notRenderInModal'),
    modalClass: '',
    dimmerClass: '',
    priority: 10,
    renderInPlace: Ember.computed.reads('isTesting'),
    renderInModal: Ember.computed.not('renderInPlace'),
    shouldWormhole: Ember.computed.and('isOpen', 'renderInModal'),
    notMasterDetail: Ember.computed.not('masterDetail'),
    notLightbox: Ember.computed.not('lightbox'),
    notSidebar: Ember.computed.not('sidebar'),
    attributeBindings: ['hidden'],
    hidden: Ember.computed.readOnly('renderInModal'),
    secondaryButtonClass: Ember.computed('basic', function () {
      let classList = 'basic';
      classList += this.basic ? ' secondary' : ' black';
      return classList;
    }),
    openObserver: Ember.observer('shouldWormhole', function () {
      Ember.run.once(this, '_handleShouldWormHole');
    }),

    _handleShouldWormHole() {
      if (this.shouldWormhole) {
        this.addToService();
      } else {
        this.removeFromService();
      }
    },

    didInsertElement() {
      this._super(...arguments);

      if (this.shouldWormhole) {
        this.addToService();
      }
    },

    willDestroy() {
      this._super(...arguments);

      this.removeFromService();
    },

    addToService() {
      this.modalService.add(this);
    },

    removeFromService() {
      this.modalService.remove(this);
      this.onModalClose();
    },

    onModalClose() {// implement
    },

    _onPrimary() {
      if (this.dismissable) {
        this.set('isOpen', false);
      }

      this.onPrimaryButtonClick();
    },

    onPrimaryButtonClick() {
      this.sendAction('action');
    },

    _onSecondary() {
      if (this.dismissable) {
        this.set('isOpen', false);
      }

      this.onSecondaryButtonClick();
    },

    onSecondaryButtonClick() {
      this.sendAction('cancel');
    },

    onHide() {
      if (this.isOpen && this.dismissable) {
        this.set('isOpen', false);
      }
    }

  });

  _exports.default = _default;
});