define("ember-nrg-ui/components/nrg-modal-container/modal-wrapper/component", ["exports", "ember-nrg-ui/mixins/resize", "ember-nrg-ui/components/nrg-modal-container/modal-wrapper/template"], function (_exports, _resize, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const isIE11 = !window.ActiveXObject && 'ActiveXObject' in window;
  const useFlexBox = !isIE11;

  var _default = Ember.Component.extend(_resize.default, {
    layout: _template.default,
    tagName: '',
    modalService: Ember.inject.service('modal'),
    isTesting: Ember.computed.readOnly('modal.isTesting'),
    isOpen: Ember.computed.alias('modal.isOpen'),
    isActive: Ember.computed.alias('modal.isActive'),
    renderInPlace: Ember.computed.alias('modal.renderInPlace'),
    hasMovedDom: Ember.computed.alias('modal.hasMovedDom'),
    masterDetail: Ember.computed.alias('modal.masterDetail'),
    sidebar: Ember.computed.alias('modal.sidebar'),
    basic: Ember.computed.alias('modal.basic'),
    lightbox: Ember.computed.alias('modal.lightbox'),
    modalClass: Ember.computed.alias('modal.modalClass'),
    dimmerClass: Ember.computed.alias('modal.dimmerClass'),
    scrolling: Ember.computed.alias('modal.scrolling'),
    notSidebar: Ember.computed.not('sidebar'),
    hasCloseIcon: Ember.computed.and('dismissable', 'notSidebar'),
    dismissable: Ember.computed.alias('modal.dismissable'),
    modalStyles: Ember.computed('modalElement', function () {
      if (useFlexBox || !this.modalElement || this.masterDetail || this.sidebar || this.lightbox) {
        return '';
      }

      const marginTop = this.modalElement.offsetHeight / 2;
      const marginLeft = this.modalElement.offsetWidth / 2;
      return Ember.String.htmlSafe(`margin-top: -${marginTop}px; margin-left: -${marginLeft}px;`);
    }),

    didResize() {
      this.notifyPropertyChange('modalStyles');
    },

    _modalClass: Ember.computed('sidebar', 'basic', 'lightbox', 'modalClass', 'masterDetail', function () {
      const appliedClasses = [];

      if (this.sidebar) {
        appliedClasses.push('sidebar-modal');
      }

      if (this.lightbox) {
        appliedClasses.push('fullscreen');
        appliedClasses.push('lightbox');
      }

      if (this.modalClass) {
        appliedClasses.push(this.modalClass);
      }

      if (this.basic) {
        appliedClasses.push('basic');
      }

      if (this.masterDetail) {
        appliedClasses.push('master-detail--takeover');
      }

      return appliedClasses.join(' ');
    }),
    _contentClass: Ember.computed('sidebar', 'lightbox', 'renderInPlace', 'masterDetail', function () {
      const appliedClasses = ['modal-content'];

      if (this.lightbox) {
        appliedClasses.push('image');
      }

      if (!this.sidebar) {
        appliedClasses.push('content');
      }

      if (this.scrolling) {
        appliedClasses.push('scrolling');
      }

      return appliedClasses.join(' ');
    }),

    addModalToWormhole(element) {
      if (this.hasMovedDom || this.renderInPlace || this.isTesting) {
        return;
      }

      this.contentNode = this.modal.element.querySelector('.modal-js');
      this._parentContentNode = element;
      element.appendChild(this.contentNode);
      this.set('hasMovedDom', true);
    },

    removeModalFromWormhole() {
      if (!this.hasMovedDom) {
        return;
      }

      if (!this.modal || this.modal.isDestroying || !this.modal.element) {
        this._parentContentNode.removeChild(this.contentNode);
      } else {
        this.modal.element.appendChild(this.contentNode);
        this.modalService.remove(this);
        this.set('hasMovedDom', false);
      }
    },

    modalElementAdded(element) {
      this.set('modalElement', element);

      if (useFlexBox || this.masterDetail || this.sidebar || this.lightbox) {
        return;
      }

      const config = {
        attributes: false,
        childList: true,
        subtree: true
      };
      this.modalMutationObserver = new MutationObserver(() => {
        this.notifyPropertyChange('modalStyles');
      });
      const observedElement = element.querySelector('.modal-content');
      this.modalMutationObserver.observe(observedElement, config);
    },

    willDestroyElement() {
      this._super(...arguments);

      if (this.modalMutationObserver) {
        this.modalMutationObserver.disconnect();
      }
    },

    onHide() {
      this.modal.onHide();
    },

    _onPrimary() {
      this.modal._onPrimary();
    },

    _onSecondary() {
      this.modal._onSecondary();
    }

  });

  _exports.default = _default;
});