define("ember-modal-dialog/templates/components/modal-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Azl99KIA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,[32,0,[\"whichModalDialogComponent\"]],[],[[\"@wrapperClass\",\"@wrapperClassNames\",\"@overlayClass\",\"@overlayClassNames\",\"@containerClass\",\"@containerClassNames\",\"@hasOverlay\",\"@translucentOverlay\",\"@clickOutsideToClose\",\"@destinationElementId\",\"@overlayPosition\",\"@tetherTarget\",\"@legacyTarget\",\"@attachment\",\"@targetAttachment\",\"@targetModifier\",\"@targetOffset\",\"@offset\",\"@tetherClassPrefix\",\"@constraints\",\"@attachmentClass\",\"@stack\",\"@value\",\"@onClickOverlay\",\"@onClose\"],[[32,0,[\"wrapperClass\"]],[32,0,[\"wrapperClassNames\"]],[32,0,[\"overlayClass\"]],[32,0,[\"overlayClassNames\"]],[32,0,[\"containerClass\"]],[32,0,[\"containerClassNames\"]],[32,0,[\"hasOverlay\"]],[32,0,[\"translucentOverlay\"]],[32,0,[\"clickOutsideToClose\"]],[32,0,[\"destinationElementId\"]],[32,0,[\"overlayPosition\"]],[32,0,[\"tetherTarget\"]],[32,0,[\"target\"]],[32,0,[\"attachment\"]],[32,0,[\"targetAttachment\"]],[32,0,[\"targetModifier\"]],[32,0,[\"targetOffset\"]],[32,0,[\"offset\"]],[32,0,[\"tetherClassPrefix\"]],[32,0,[\"constraints\"]],[32,0,[\"attachmentClass\"]],[32,0,[\"stack\"]],[32,0,[\"value\"]],[32,0,[\"onClickOverlayAction\"]],[32,0,[\"onCloseAction\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-modal-dialog/templates/components/modal-dialog.hbs"
    }
  });

  _exports.default = _default;
});