define("ember-nrg-ui/modal/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    items: Ember.A(),
    renderIndex: 0,
    renderedModals: Ember.computed.filterBy('items', 'renderInPlace', false),
    _openModals: Ember.computed.filterBy('renderedModals', 'isOpen', true),
    modalSort: Object.freeze(['priority:asc', 'renderIndex:asc']),
    openModals: Ember.computed.sort('_openModals', 'modalSort'),
    activeModal: Ember.computed.readOnly('openModals.lastObject'),
    hasOpenModals: Ember.computed.notEmpty('openModals'),

    add(item) {
      this.get('items').pushObject(item);
      item.set('renderIndex', this.renderIndex);
      this.incrementProperty('renderIndex');
    },

    remove(item) {
      this.get('items').removeObject(item);
    }

  });

  _exports.default = _default;
});