define("ember-nrg-ui/components/nrg-list/items/component", ["exports", "ember-nrg-ui/components/nrg-list/items/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function createArrayProxy(content) {
    if (Ember.ArrayProxy.create) {
      return Ember.ArrayProxy.create({
        content
      });
    }

    return new Ember.ArrayProxy({
      content
    });
  }

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['ui', 'attached', 'segment', 'nrg-list'],
    classNameBindings: ['loading:disabled'],
    noResultsLabel: 'No Results',
    selectionType: '',
    selected: Ember.A([]),
    canSelect: Ember.computed.bool('selectionType'),
    canShowActiveItem: Ember.computed('selected.[]', function () {
      const selected = this.get('selected');
      return !Ember.isEmpty(selected);
    }),

    init() {
      this._super(...arguments);

      const items = this.get('items');

      if (items) {
        this.get('_items').pushObjects(items.toArray && items.toArray() || items);
      }
    },

    _items: Ember.computed(function () {
      return createArrayProxy(Ember.A());
    }),
    _start: Ember.computed.readOnly('pageMeta.start'),
    _total: Ember.computed.readOnly('pageMeta.total'),
    currentPage: Ember.computed('_start', 'selectedPageSize', function () {
      return this.get('_start') / this.get('selectedPageSize') + 1;
    }),
    totalPages: Ember.computed('selectedPageSize', '_total', function () {
      return Math.ceil(this.get('_total') / this.get('selectedPageSize'));
    }),
    canStepForward: Ember.computed('currentPage', 'totalPages', function () {
      return this.get('currentPage') < this.get('totalPages');
    }),
    itemsObserver: Ember.observer('items', function () {
      const items = this.get('items');
      const start = this.get('_start');

      if (!start) {
        this.set('_items', createArrayProxy(Ember.A()));
      }

      this.get('_items').pushObjects(items.toArray && items.toArray() || items);
    }),
    actions: {
      select(item) {
        let selected = Ember.A([item]);

        if (!this.isSelectable(item)) {
          return;
        }

        const selectionType = this.get('selectionType');

        if (selectionType === 'multiple') {
          if (this.get('selected').includes(item)) {
            selected = Ember.A(this.get('selected').without(item));
          } else {
            selected = Ember.A(this.get('selected').concat(selected));
          }
        } else if (!selectionType) {
          return;
        }

        if (selectionType !== 'click') {
          this.set('selected', selected);
        }

        this.itemClicked(item, selected);
      },

      nextPage() {
        const pageSize = this.get('selectedPageSize');
        const start = this.get('pageMeta.start');
        this.changePage(start + pageSize);
      }

    }
  });

  _exports.default = _default;
});