define("ember-nrg-ui/components/nrg-button/component", ["exports", "ember-nrg-ui/components/nrg-button/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'button',
    type: 'button',
    classNames: ['ui button'],
    classNameBindings: ['disabled', 'loading'],
    attributeBindings: ['type', '_disabled:disabled', 'name', 'tabindex', 'title', 'autofocus'],
    loading: false,
    disabled: false,
    _disabled: Ember.computed.or('disabled', 'loading'),
    click: function (evt) {
      this.onClick(evt);
    },

    onClick(evt) {
      this.sendAction('action', evt);
    }

  });

  _exports.default = _default;
});