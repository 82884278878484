define("ember-nrg-ui/components/nrg-dropdown/component", ["exports", "ember-keyboard", "ember-nrg-ui/mixins/validation", "ember-nrg-ui/components/nrg-dropdown/template"], function (_exports, _emberKeyboard, _validation, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validation.default, _emberKeyboard.EKMixin, _emberKeyboard.EKFirstResponderOnFocusMixin, {
    layout: _template.default,
    tagName: 'div',
    defaultText: 'Select an Option',
    disabled: false,
    loading: false,
    dropdownAction: 'activate',
    direction: undefined,
    allowTab: true,
    selected: null,
    forceSelection: false,
    showOnFocus: true,
    allowAdditions: false,
    isAddingOption: false,
    hideAdditions: true,
    menuDirection: '',
    match: 'both',
    isOpen: false,
    searchProperty: undefined,
    activeItem: -1,
    classNames: ['ui', 'dropdown'],
    classNameBindings: ['selection', 'search', 'search:selection', 'loading', 'loading:disabled', 'disabled', 'isOpen:active', 'isOpen:visible', 'multiple'],
    _selection: Ember.computed.notEmpty('field'),
    selection: Ember.computed.reads('_selection'),
    _hideAction: Ember.computed.equal('dropdownAction', 'hide'),
    hideAction: Ember.computed.or('_hideAction', 'freeform'),
    notHideAction: Ember.computed.not('hideAction'),
    hasSelected: Ember.computed.and('selected', 'notHideAction'),
    hasDefaultText: Ember.computed.readOnly('notHideAction'),
    isSearching: Ember.computed.and('search', 'searchValue'),
    isStringData: Ember.computed('options', function () {
      return this.get('options.length') && typeof this.options[0] == 'string';
    }),

    init() {
      this._super(...arguments);

      this.optionsObserver();
    },

    didInsertElement() {
      this._super(...arguments);

      this.createClickHandler();
    },

    willDestroyElement() {
      this._super(...arguments);

      this.removeWindowClickListener();
    },

    addWindowClickListener() {
      document.addEventListener('click', this._clickHandler, true);
    },

    removeWindowClickListener() {
      document.removeEventListener('click', this._clickHandler, true);
    },

    createClickHandler() {
      this.set('_clickHandler', evt => {
        if (this.element && !this.element.contains(evt.target)) {
          this.set('isOpen', false);
          this.set('activeItem', -1);
          this.focusInput(false);
        }

        return true;
      });
    },

    isOpenObserver: Ember.observer('isOpen', function () {
      Ember.run.next(() => {
        if (this.get('isOpen')) {
          this.addWindowClickListener();
        } else {
          this.removeWindowClickListener();
        }
      });
    }),
    optionsObserver: Ember.observer('options', function () {
      this.set('activeItem', -1);
      Ember.run.next(() => {
        if (this.forceSelection && !this.hasSelected && this.get('options.length')) {
          this._onSelect(this.options[0]);
        }
      });
    }),
    moveUp: Ember.on((0, _emberKeyboard.keyDown)('ArrowUp'), function (evt) {
      if (!this.isOpen) {
        return;
      }

      evt.preventDefault();
      evt.stopPropagation();

      if (this.activeItem > 0) {
        this.decrementProperty('activeItem');
      }
    }),
    moveDown: Ember.on((0, _emberKeyboard.keyDown)('ArrowDown'), function (evt) {
      if (!this.isOpen) {
        return;
      }

      evt.preventDefault();
      evt.stopPropagation();

      if (this.activeItem < this.get('displayedOptions.length') - 1) {
        this.incrementProperty('activeItem');
      }
    }),
    enter: Ember.on((0, _emberKeyboard.keyDown)('Enter'), function (evt) {
      const validRange = this.activeItem >= 0 && this.activeItem < this.get('displayedOptions.length');

      if (!this.isOpen || !validRange) {
        return;
      }

      evt.preventDefault();
      evt.stopPropagation();

      this._onSelect(this.displayedOptions[this.activeItem]);
    }),
    menuClass: Ember.computed('menuDirection', 'isOpen', function () {
      let computedClasses = '';

      if (this.menuDirection) {
        computedClasses += ` ${this.menuDirection}`;
      }

      if (this.isOpen) {
        computedClasses += ' transition visible';
      } else {
        computedClasses += ' transition hidden';
      }

      return computedClasses;
    }),
    textClass: Ember.computed('hasSelected', 'hasDefaultText', 'isSearching', function () {
      if (this.isSearching) {
        return 'filtered';
      }

      if (this.hasSelected) {
        return '';
      }

      if (this.hasDefaultText) {
        return 'default';
      }

      return '';
    }),
    displayedOptions: Ember.computed('options', 'selected.[]', 'searchValue', 'isSearching', 'allowAdditions', 'hideAdditions', function () {
      this.set('isAddingOption', false);
      let options = this.options;

      if (this.multiple) {
        options = options.filter(option => {
          return !this.isCurrentlySelected(option);
        });
      }

      if (!this.isSearching) {
        return options;
      } else {
        const filteredOptions = options.filter(option => {
          return this.isSearchMatch(option, this.searchValue);
        });

        if (this.allowAdditions && filteredOptions.length == 0) {
          this.set('isAddingOption', true);
          const addedOption = this.isStringData ? this.searchValue : {
            label: this.searchValue,
            value: this.searchValue
          };
          return [addedOption];
        } else {
          return filteredOptions;
        }
      }
    }),

    isSearchMatch(option, searchValue) {
      if (typeof option == 'string') {
        return this.stringContains(option, searchValue);
      } else if (this.searchProperty) {
        const optionAttribute = option[this.searchProperty];

        if (optionAttribute && typeof optionAttribute == 'string') {
          return this.stringContains(optionAttribute, searchValue);
        } else if (optionAttribute && typeof optionAttribute == 'number') {
          return this.stringContains(optionAttribute.toString(), searchValue);
        }
      } else {
        return this.stringContains(option.label, searchValue) || this.stringContains(option.value, searchValue);
      }
    },

    stringContains(s1, s2) {
      if (!s2 || typeof s2 != 'string') {
        return true;
      }

      if (!s1 || typeof s1 != 'string') {
        return false;
      }

      return s1.toLowerCase().indexOf(s2.toLowerCase()) != -1;
    },

    click(evt) {
      const isMultiSelection = evt.target.closest('[data-dropdown-multi-selection]');
      const isDropdownItem = evt.target.closest('[data-dropdown-item]');

      if (isMultiSelection || isDropdownItem) {
        return;
      }

      if (this.isOpen) {
        this.set('isOpen', false);
      } else {
        this.set('isOpen', true);
        this.focusInput(true);
      }
    },

    focusInput(focus) {
      if (!this.search) {
        return;
      }

      const input = this.element.querySelector('input');

      if (focus) {
        input.focus();
      } else {
        this.set('searchValue', '');
        input.blur();
      }
    },

    isCurrentlySelected(option) {
      if (this.multiple) {
        if (!Array.isArray(this.selected)) {
          return false;
        }

        return this.selected.indexOf(option) != -1;
      } else {
        return option === this.selected;
      }
    },

    unselectOption(option) {
      this.selected.removeObject(option);
    },

    _onSelect(option) {
      if (!option) {
        this.set('isOpen', false);
        this.focusInput(false);
      }

      const notCurrentlySelected = !this.isCurrentlySelected(option);

      if (notCurrentlySelected) {
        if (this.multiple) {
          if (!Array.isArray(this.selected)) {
            this.set('selected', Ember.A());
          }

          this.selected.pushObject(option);
        } else {
          this.set('selected', option);
        }
      }

      if (this.hideAction || notCurrentlySelected) {
        this.onSelect(option);
      }

      if (this.isSearching) {
        this.set('searchValue', '');
      }

      if (this.isAddingOption && !this.hideAdditions) {
        this.get('options.push') && this.options.push(option);
      }

      if (!this.multiple) {
        this.set('isOpen', false);
        this.focusInput(false);
      }

      this.set('isAddingOption', false);
      this.set('activeItem', -1);
    },

    onSelect(option) {
      this.sendAction('action', option);
    }

  });

  _exports.default = _default;
});