define("ember-nrg-ui/helpers/format-number", ["exports", "ember-nrg-ui/helpers/unformat"], function (_exports, _unformat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatNumber = formatNumber;
  _exports.numberFormatter = numberFormatter;

  function isDecimalNumber(value) {
    return value % 1 !== 0;
  }

  function formatNumber() {
    let value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    let decimalPlaces = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    value = (0, _unformat.default)(value + '');
    const absoluteValue = Math.abs(value);
    let formattedValue = absoluteValue + '';

    if (decimalPlaces !== undefined || isDecimalNumber(value)) {
      formattedValue = absoluteValue.toFixed(decimalPlaces);
    }

    while (/(\d+)(\d{3})/.test(formattedValue)) {
      formattedValue = formattedValue.replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
    }

    return formattedValue;
  }

  function numberFormatter(_ref) {
    let [value, decimal] = _ref;
    const formattedValue = formatNumber(value, decimal);
    return (0, _unformat.default)(value) < 0 ? '-' + formattedValue : formattedValue;
  }

  var _default = Ember.Helper.helper(numberFormatter);

  _exports.default = _default;
});