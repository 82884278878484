define("ember-nrg-ui/components/nrg-sidebar-menu/component", ["exports", "ember-nrg-ui/components/nrg-sidebar-menu/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    sidebarMenuManager: Ember.inject.service(),
    classNames: ['ui', 'vertical', 'left', 'menu', 'sidebar-menu'],
    appReloadLocation: '/',
    visibleMenuItems: Ember.computed.filterBy('sidebarMenuManager.menuItems', 'isShownInSidebar', true),
    visibleFooterItems: Ember.computed.filterBy('sidebarMenuManager.footerMenuItems', 'isShownInSidebar', true),
    hasVisibleFooterItems: Ember.computed.notEmpty('visibleFooterItems'),
    actions: {
      sidebarAction(menuItem) {
        menuItem.sidebarAction();
      },

      clickedLink(item) {
        if (this.clickedSidebarItem) {
          this.clickedSidebarItem(item);
        }
      }

    }
  });

  _exports.default = _default;
});