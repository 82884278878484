define("ember-nrg-ui/components/nrg-lightbox-container/keyboard-shortcuts/component", ["exports", "ember-nrg-ui/mixins/global-keyboard-shortcut"], function (_exports, _globalKeyboardShortcut) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_globalKeyboardShortcut.default, {
    lightboxService: Ember.inject.service('lightbox'),
    shortcutHeader: 'Photo Lightbox',
    keyboardShortcuts: [{
      key: 'ArrowLeft',
      actionName: 'leftPressed',
      description: 'Previous Photo'
    }, {
      key: 'ArrowRight',
      actionName: 'rightPressed',
      description: 'Next Photo'
    }],
    previousDisabled: Ember.computed.alias('lightboxService.previousDisabled'),
    nextDisabled: Ember.computed.alias('lightboxService.nextDisabled'),
    actions: {
      leftPressed() {
        if (!this.get('previousDisabled')) {
          this.get('lightboxService').selectPrevious();
        }
      },

      rightPressed() {
        if (!this.get('nextDisabled')) {
          this.get('lightboxService').selectNext();
        }
      }

    }
  });

  _exports.default = _default;
});