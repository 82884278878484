define("ember-nrg-ui/mixins/validation-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    didValidate: false,

    showValidation() {
      this.set('didValidate', true);
    },

    hideValidation() {
      this.set('didValidate', false);
    }

  });

  _exports.default = _default;
});