define("ember-nrg-ui/components/nrg-text-area/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L3Na9NA6",
    "block": "{\"symbols\":[],\"statements\":[[8,\"textarea\",[],[[\"@value\",\"@rows\",\"@cols\",\"@maxlength\",\"@name\",\"@placeholder\",\"@disabled\"],[[34,2],[34,5],[34,6],[34,7],[34,8],[34,9],[34,10]]],null],[2,\"\"],[6,[37,1],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"text character-limit \",[30,[36,1],[[35,0],\"red\"],null]]]],[12],[2,\"\\n    \"],[1,[30,[36,3],[[35,2,[\"length\"]],0],null]],[2,\"/\"],[1,[34,4]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"overCharacterLimit\",\"if\",\"value\",\"or\",\"characterLimit\",\"rows\",\"cols\",\"maxlength\",\"name\",\"placeholder\",\"disabled\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-text-area/template.hbs"
    }
  });

  _exports.default = _default;
});