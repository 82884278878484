define("ember-render-helpers/helpers/did-insert", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This helper is activated only when it is rendered for the first time
   * (inserted in the DOM). It does not run during or after it is un-rendered
   * (removed from the DOM), or when its arguments are updated.
   */
  class DidInsertHelper extends Ember.Helper {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "didRun", false);
    }

    compute(positional, named) {
      const fn = positional[0];
      (true && !(typeof fn === 'function') && Ember.assert(`\`{{did-insert fn}}\` expects a function as the first parameter. You provided: ${fn}`, typeof fn === 'function'));
      if (this.didRun) return;
      this.didRun = true;
      fn(positional.slice(1), named);
    }

  }

  _exports.default = DidInsertHelper;
});