define("ember-nrg-ui/components/nrg-dropdown/item/component", ["exports", "ember-nrg-ui/components/nrg-dropdown/item/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    _onClick(option) {
      if (this.onSelect) {
        this.onSelect(option);

        this._onSelect();
      } else if (option) {
        this._onSelect(option);
      }
    },

    _onSelect() {// Implemented by menu
    } // onSelect() implemented by user


  });

  _exports.default = _default;
});