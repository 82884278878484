define("ember-modal-dialog/components/liquid-dialog", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@ember-decorators/component", "ember-modal-dialog/components/basic-dialog", "ember-modal-dialog/templates/components/liquid-dialog"], function (_exports, _defineProperty2, _component, _basicDialog, _liquidDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let LiquidDialog = (_dec = (0, _component.layout)(_liquidDialog.default), _dec(_class = class LiquidDialog extends _basicDialog.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "hasOverlay", true);
      (0, _defineProperty2.default)(this, "variantWrapperClass", 'emd-animatable');
    }

    init() {
      super.init(...arguments);
      this.containerClassNames?.push('liquid-dialog');
    }

  }) || _class);
  _exports.default = LiquidDialog;
});