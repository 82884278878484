define("ember-nrg-ui/keyboard-shortcut/service", ["exports", "ember-keyboard", "ember-nrg-ui/keyboard-shortcut/modifier-keys"], function (_exports, _emberKeyboard, _modifierKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_emberKeyboard.EKMixin, _emberKeyboard.EKOnInitMixin, {
    registeredClients: Ember.A(),
    lastCode: null,
    modalIsOpen: false,
    shortcuts: Ember.computed.sort('_shortcuts', 'shortcutsSort'),
    shortcutsSort: ['priority:desc', 'shortcutRegisteredTime:asc', 'description:asc'],
    _shortcuts: Ember.computed('registeredClients.[]', function () {
      const shortcuts = Ember.A();
      this.get('registeredClients').forEach(client => {
        shortcuts.addObjects(client.get('_mappedShortcuts'));
      });
      return shortcuts;
    }),
    keyTrigger: Ember.on((0, _emberKeyboard.keyDown)(), function (event) {
      const code = (0, _emberKeyboard.getCode)(event);
      const lastCode = this.get('lastCode'); // Ignore modifier keys

      if (_modifierKeys.default.includes(code)) {
        return;
      }

      const firstMatchingShortcut = this.get('shortcuts').find(shortcut => {
        const altMatch = (event.altKey || false) == shortcut.alt;
        const shftMatch = (event.shiftKey || false) == shortcut.shft;
        const ctrlOrMeta = event.ctrlKey || event.metaKey;
        const ctrlMatch = shortcut.ctrl ? ctrlOrMeta : !ctrlOrMeta;
        const modKeysMatch = altMatch && shftMatch && ctrlMatch;
        const codeMatch = code == shortcut.code;
        const lastCodeMatch = lastCode == shortcut.lastCode;
        const lastCodeTrueMatch = shortcut.lastCode ? lastCodeMatch : true;
        return modKeysMatch && codeMatch && lastCodeTrueMatch;
      });

      if (firstMatchingShortcut) {
        this.set('modalIsOpen', false);
        firstMatchingShortcut.client.send(firstMatchingShortcut.actionName, event);
        this.set('lastCode', null);
        event.preventDefault();
      } else {
        this.set('lastCode', code);
      }
    }),

    mapClientShortcuts(client) {
      const shortcutRegisteredTime = client.get('shortcutRegisteredTime');
      const clientHeader = client.get('shortcutHeader');
      return client.get('keyboardShortcuts').map(clientShortcut => {
        let code = clientShortcut.key;
        let lastCode = null;

        if (Ember.isArray(clientShortcut.key)) {
          (true && !(clientShortcut.key.length == 2) && Ember.assert('Chained shortcuts longer than two keys are not currently supported.', clientShortcut.key.length == 2));
          lastCode = clientShortcut.key[0];
          code = clientShortcut.key[1];
        }

        const header = clientShortcut.header || clientHeader || '';
        (true && !(clientShortcut.description) && Ember.assert('Shortcuts must have a description', clientShortcut.description));
        return {
          client,
          code,
          lastCode,
          header,
          shortcutRegisteredTime,
          priority: clientShortcut.priority || 10,
          description: clientShortcut.description,
          alt: clientShortcut.alt || false,
          ctrl: clientShortcut.ctrl || false,
          shft: clientShortcut.shft || false,
          actionName: clientShortcut.actionName
        };
      });
    },

    registerKeyboardShortcuts(client) {
      client.set('shortcutRegisteredTime', new Date());
      const mappedShortcuts = this.mapClientShortcuts(client);
      mappedShortcuts.forEach(shortcut => {
        const matchingShortcut = this.get('shortcuts').find(registeredShortcut => {
          const altMatch = registeredShortcut.alt == shortcut.alt;
          const shftMatch = registeredShortcut.shft == shortcut.shft;
          const ctrlMatch = registeredShortcut.ctrl == shortcut.ctrl;
          const modKeysMatch = altMatch && shftMatch && ctrlMatch;
          const codeMatch = registeredShortcut.code == shortcut.code;
          const lastCodeMatch = registeredShortcut.lastCode == shortcut.lastCode;
          return modKeysMatch && codeMatch && lastCodeMatch;
        });
        (true && !(!matchingShortcut) && Ember.assert(`Registering two shortcuts with the same path: [${shortcut.description}] and [${matchingShortcut.description}]`, !matchingShortcut));
      });
      client.set('_mappedShortcuts', mappedShortcuts);
      this.get('registeredClients').addObject(client);
    },

    unregisterKeyboardShortcuts(client) {
      this.get('registeredClients').removeObject(client);
    }

  });

  _exports.default = _default;
});