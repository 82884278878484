define("ember-nrg-ui/components/nrg-sidebar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d1n6HKcU",
    "block": "{\"symbols\":[],\"statements\":[[8,\"nrg-modal\",[],[[\"@sidebar\",\"@isOpen\"],[true,[34,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"nrg-sidebar-menu\",[],[[\"@clickedSidebarItem\"],[[30,[36,2],[[32,0],[35,1]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isOpen\",\"clickedLink\",\"action\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-sidebar/template.hbs"
    }
  });

  _exports.default = _default;
});