define("ember-nrg-ui/components/nrg-modal-container/component", ["exports", "ember-nrg-ui/components/nrg-modal-container/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    modalService: Ember.inject.service('modal'),
    openModals: Ember.computed.readOnly('modalService.openModals'),
    activeModal: Ember.computed.readOnly('modalService.activeModal'),
    hasOpenModals: Ember.computed.readOnly('modalService.hasOpenModals'),
    activeModalIsSidebar: Ember.computed.readOnly('activeModal.sidebar'),
    _containerClass: Ember.computed('hasOpenModals', 'activeModalIsSidebar', function () {
      let appliedClass = '';

      if (this.hasOpenModals) {
        appliedClass += ' visible active';
      } else {
        appliedClass += ' hidden';
      }

      if (this.activeModalIsSidebar) {
        appliedClass += ' sidebar-container';
      }

      return appliedClass;
    }),
    dimmerClass: Ember.computed('activeModal.sidebar', 'activeModal.dimmerClass', 'activeModal.dismissable', function () {
      const activeModal = this.activeModal;

      if (!activeModal) {
        return '';
      }

      let appliedClass = '';

      if (activeModal.sidebar) {
        appliedClass += ' sidebar-dimmer';
      } else {
        appliedClass += ' page';
      }

      if (!activeModal.dismissable) {
        appliedClass += ' not-dismissable';
      }

      appliedClass += ' ' + activeModal.dimmerClass;
      return appliedClass;
    }),

    onDimmerClick() {
      if (!this.activeModal.dismissable) {
        return;
      }

      this.activeModal.onHide();
    }

  });

  _exports.default = _default;
});