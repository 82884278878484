define("ember-nrg-ui/components/nrg-application/component", ["exports", "ember-nrg-ui/mixins/resize", "ember-nrg-ui/components/nrg-application/template"], function (_exports, _resize, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_resize.default, {
    layout: _template.default,
    application: Ember.inject.service(),
    lightbox: Ember.inject.service(),
    modal: Ember.inject.service(),
    responsive: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    sidebarIsOpen: false,
    sidebarMenuIsOpen: Ember.computed.alias('application.sidebarMenuIsOpen'),
    classNames: ['nrg-application'],
    classNameBindings: ['fullscreenMap:fullscreen-map', 'computerScreenSidebarActive:large-screen-sidebar-active'],
    isComputerScreen: Ember.computed.alias('responsive.isComputerScreenGroup'),
    title: Ember.computed.reads('application.pageTitle'),

    init() {
      this._super(...arguments);

      this.set('sidebarMenuIsOpen', true);
    },

    computerScreenSidebarActive: Ember.computed.and('isComputerScreen', 'sidebarMenuIsOpen'),
    mainContentStyle: Ember.computed('screenHeight', function () {
      return Ember.String.htmlSafe(`height:${this.get('screenHeight')}px`);
    }),
    actions: {
      toggleSidebar() {
        if (this.get('isComputerScreen')) {
          this.toggleProperty('sidebarMenuIsOpen');
        } else {
          this.toggleProperty('sidebarIsOpen');
        }
      },

      clickedLink(item) {
        if (this.clickedSidebarItem) {
          this.clickedSidebarItem(item);
        }
      }

    }
  });

  _exports.default = _default;
});