define("ember-nrg-ui/mixins/focus-first-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init() {
      this._super(...arguments);

      Ember.run.scheduleOnce('afterRender', this, 'focusFirstInput');
    },

    focusFirstInput() {
      Ember.run.next(() => {
        if (this.isDestroying) {
          return;
        }

        const inputs = this.element.querySelectorAll('input[type=text]:enabled');
        inputs && inputs[0] && inputs[0].focus();
      });
    }

  });

  _exports.default = _default;
});