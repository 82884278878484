define("ember-nrg-ui/components/nrg-form-container/component", ["exports", "ember-nrg-ui/components/nrg-form-container/template", "ember-nrg-ui/mixins/validation-container"], function (_exports, _template, _validationContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validationContainer.default, {
    layout: _template.default,
    tagName: 'form',
    classNames: ['ui', 'form'],
    classNameBindings: ['error', 'loading'],
    hasFormLinks: Ember.computed.notEmpty('formLinks'),
    submit: function (evt) {
      evt.preventDefault();
      evt.stopPropagation();
      this.sendAction('action', this.get('param'));
      this.showValidation();
    },
    actions: {
      cancel() {
        this.sendAction('cancel');
      },

      publishFormLinkAction(action) {
        this.sendAction(action);
      }

    }
  });

  _exports.default = _default;
});