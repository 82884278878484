define("ember-nrg-ui/components/nrg-responsive-takeover/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YUn4mENT",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"nrg-modal\",[],[[\"@priority\",\"@renderInPlace\",\"@isOpen\",\"@dismissable\",\"@masterDetail\"],[0,[34,2],true,false,true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"nrg-appbar\",[],[[\"@showReleaseNotes\",\"@title\",\"@showBackArrow\",\"@onBackArrowClick\"],[false,[34,0],true,[30,[36,1],[[32,0],[32,0,[\"onBackArrowClick\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[15,0,[30,[36,4],[[35,3],\"main-content\",\"\"],null]],[15,5,[34,5]],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\",\"action\",\"renderInPlace\",\"renderInModal\",\"if\",\"mainContentStyle\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-responsive-takeover/template.hbs"
    }
  });

  _exports.default = _default;
});