define("ember-nrg-ui/components/nrg-loading-indicator/component", ["exports", "ember-nrg-ui/components/nrg-loading-indicator/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    centered: true,
    loading: true,
    inline: true,
    classNames: ['ui', 'loader'],
    classNameBindings: ['loading:active:disabled', 'centered', 'inline', 'text:text', 'inverted', 'indeterminate', 'size']
  });

  _exports.default = _default;
});