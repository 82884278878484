define("ember-nrg-ui/components/nrg-dropdown/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ina74yng",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,\"data-dropdown-item\",\"true\"],[24,\"role\",\"button\"],[16,0,[31,[[30,[36,1],[[35,2],\"disabled\"],null],\" \",[30,[36,1],[[35,0],\"active\"],null],\" item\"]]],[17,1],[4,[38,5],[[32,0],[35,4],[35,3]],[[\"preventDefault\"],[false]]],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"active\",\"if\",\"disabled\",\"option\",\"_onClick\",\"action\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-dropdown/item/template.hbs"
    }
  });

  _exports.default = _default;
});