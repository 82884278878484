define("ember-nrg-ui/components/nrg-sidebar-menu-item/component", ["exports", "ember-nrg-ui/components/nrg-sidebar-menu-item/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    isVisible: Ember.computed.readOnly('item.isShownInSidebar'),
    isParentGroup: false,
    _linkToItemClass: Ember.computed('isParentGroup', function () {
      return this.get('isParentGroup') ? 'parent-route' : 'item';
    })
  });

  _exports.default = _default;
});