define("ember-nrg-ui/mixins/context-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    contextService: Ember.inject.service('context-menu'),
    concatenatedProperties: ['contextItems'],
    contextItems: [],

    registerContextItems() {
      this.get('contextService').addClient(this);
    },

    unregisterContextItems() {
      this.get('contextService').removeClient(this);
    },

    didInsertElement() {
      this._super(...arguments);

      Ember.run.scheduleOnce('afterRender', this, 'registerContextItems');
    },

    willDestroyElement() {
      this._super(...arguments);

      Ember.run.scheduleOnce('afterRender', this, 'unregisterContextItems');
    },

    activate() {
      this._super(...arguments);

      this.registerContextItems();
    },

    deactivate() {
      this._super(...arguments);

      this.unregisterContextItems();
    }

  });

  _exports.default = _default;
});