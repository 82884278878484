define("ember-nrg-ui/components/nrg-popup/popup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DfvHGAM0",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,9],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"tether-anchor\"],[12],[2,\"\\n    \"],[8,\"ember-tether\",[],[[\"@bodyElement\",\"@target\",\"@targetAttachment\",\"@attachment\"],[[34,0],[34,1],[34,2],[34,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[11,\"div\"],[24,\"role\",\"button\"],[16,0,[31,[\"ui popup \",[34,4],\" visible\"]]],[4,[38,6],[[32,0],[35,5]],[[\"on\"],[\"mouseEnter\"]]],[4,[38,6],[[32,0],[35,7]],[[\"on\"],[\"mouseLeave\"]]],[12],[2,\"\\n        \"],[18,1,null],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"popupContainer\",\"target\",\"targetAttachment\",\"popupAttachment\",\"_popupClass\",\"onMouseEnter\",\"action\",\"onMouseLeave\",\"isOpen\",\"if\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-popup/popup/template.hbs"
    }
  });

  _exports.default = _default;
});