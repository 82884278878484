define("ember-nrg-ui/components/nrg-home-cards/home-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mwCHWtQU",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"image icon\"],[12],[2,\"\\n    \"],[10,\"i\"],[15,0,[31,[\"circular \",[34,6],\" icon\"]]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"image\"],[12],[2,\"\\n    \"],[10,\"img\"],[15,\"src\",[34,4]],[15,\"alt\",[34,5]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"header\"],[12],[1,[34,7]],[13],[2,\"\\n\"],[6,[37,1],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"meta\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"ui label\"],[12],[1,[34,3]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"description\"],[12],[2,\"\\n      \"],[10,\"p\"],[12],[1,[34,2]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[6,[37,1],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"floating ui circular red label\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"icon loading spinner\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"floating ui circular red label\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"badge\",\"if\",\"description\",\"meta\",\"image\",\"altText\",\"icon\",\"label\",\"badgeLoading\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-home-cards/home-card/template.hbs"
    }
  });

  _exports.default = _default;
});