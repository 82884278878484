define("ember-modal-dialog/components/modal-dialog", ["exports", "@embroider/macros/es-compat", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-modal-dialog/templates/components/modal-dialog", "@embroider/util"], function (_exports, _esCompat, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _modalDialog, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  const VALID_OVERLAY_POSITIONS = ['parent', 'sibling'];
  let ModalDialog = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_modalDialog.default), _dec3 = Ember.inject.service('modal-dialog'), _dec4 = Ember.computed.readOnly('modalService.hasLiquidWormhole'), _dec5 = Ember.computed.readOnly('modalService.hasLiquidTether'), _dec6 = Ember.computed.oneWay('elementId'), _dec7 = Ember.computed('attachment'), _dec8 = Ember.computed('renderInPlace', 'tetherTarget', 'animatable', 'hasLiquidWormhole', 'hasLiquidTether'), _dec9 = Ember._action, _dec10 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class ModalDialog extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "modalService", _descriptor, this);
      (0, _defineProperty2.default)(this, "animatable", null);
      (0, _defineProperty2.default)(this, "clickOutsideToClose", false);
      (0, _defineProperty2.default)(this, "destinationElementId", null);
      (0, _defineProperty2.default)(this, "hasOverlay", true);
      (0, _defineProperty2.default)(this, "overlayPosition", 'parent');
      (0, _defineProperty2.default)(this, "renderInPlace", false);
      (0, _defineProperty2.default)(this, "targetAttachment", 'middle center');
      (0, _defineProperty2.default)(this, "tetherClassPrefix", null);
      (0, _defineProperty2.default)(this, "tetherTarget", null);
      (0, _defineProperty2.default)(this, "translucentOverlay", false);
      (0, _defineProperty2.default)(this, "value", 0);
      (0, _initializerDefineProperty2.default)(this, "hasLiquidWormhole", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "hasLiquidTether", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "stack", _descriptor4, this);
    }

    // pass a `stack` string to set a "stack" to be passed to liquid-wormhole / liquid-tether
    get attachmentClass() {
      let attachment = this.attachment;

      if (Ember.isEmpty(attachment)) {
        return undefined;
      }

      return attachment.split(' ').map(attachmentPart => {
        return `emd-attachment-${Ember.String.dasherize(attachmentPart)}`;
      }).join(' ');
    }

    get whichModalDialogComponent() {
      let {
        animatable,
        hasLiquidTether,
        hasLiquidWormhole,
        tetherTarget
      } = this;
      let module = (0, _esCompat.default)(require("ember-modal-dialog/components/basic-dialog"));

      if (this.renderInPlace) {
        module = (0, _esCompat.default)(require("ember-modal-dialog/components/in-place-dialog"));
      } else if (tetherTarget && hasLiquidTether && hasLiquidWormhole && animatable === true) {
        module = (0, _esCompat.default)(require("ember-modal-dialog/components/liquid-tether-dialog"));
      } else if (tetherTarget) {
        this.ensureEmberTetherPresent();
        module = (0, _esCompat.default)(require("ember-modal-dialog/components/tether-dialog"));
      } else if (hasLiquidWormhole && animatable === true) {
        module = (0, _esCompat.default)(require("ember-modal-dialog/components/liquid-dialog"));
      }

      return (0, _util.ensureSafeComponent)(module.default, this);
    }

    init() {
      super.init(...arguments);

      if (!this.destinationElementId) {
        Ember.set(this, 'destinationElementId', this.modalService.destinationElementId);
      }
    }

    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);

      if (true
      /* DEBUG */
      ) {
        this.validateProps();
      }
    }

    validateProps() {
      let overlayPosition = this.overlayPosition;

      if (VALID_OVERLAY_POSITIONS.indexOf(overlayPosition) === -1) {
        (true && Ember.warn(`overlayPosition value '${overlayPosition}' is not valid (valid values [${VALID_OVERLAY_POSITIONS.join(', ')}])`, false, {
          id: 'ember-modal-dialog.validate-overlay-position'
        }));
      }
    }

    ensureEmberTetherPresent() {
      if (!this.modalService.hasEmberTether) {
        throw new Error('Please install ember-tether in order to pass a tetherTarget to modal-dialog');
      }
    }

    onCloseAction() {
      const onClose = this.onClose; // we shouldn't warn if the callback is not provided at all

      if (Ember.isNone(onClose)) {
        return;
      }

      (true && !(Ember.typeOf(onClose) === 'function') && Ember.assert('onClose handler must be a function', Ember.typeOf(onClose) === 'function'));
      onClose();
    }

    onClickOverlayAction(e) {
      e.preventDefault();
      const onClickOverlay = this.onClickOverlay; // we shouldn't warn if the callback is not provided at all

      if (Ember.isNone(onClickOverlay)) {
        this.onCloseAction();
        return;
      }

      (true && !(Ember.typeOf(onClickOverlay) === 'function') && Ember.assert('onClickOverlay handler must be a function', Ember.typeOf(onClickOverlay) === 'function'));
      onClickOverlay();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "modalService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hasLiquidWormhole", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hasLiquidTether", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "stack", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "attachmentClass", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "attachmentClass"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "whichModalDialogComponent", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "whichModalDialogComponent"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "onCloseAction", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "onCloseAction"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "onClickOverlayAction", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "onClickOverlayAction"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = ModalDialog;
});