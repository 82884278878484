define("ember-modal-dialog/components/basic-dialog", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-modal-dialog/templates/components/basic-dialog"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _basicDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _class2, _descriptor;

  let BasicDialog = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_basicDialog.default), _dec3 = Ember.inject.service('modal-dialog'), _dec4 = Ember.computed('attachmentClass', 'containerClass', 'containerClassNames.{[],join}', 'targetAttachmentClass'), _dec5 = Ember.computed('overlayClass', 'overlayClassNames.{[],join}', 'translucentOverlay'), _dec6 = Ember.computed('targetAttachmentClass', 'variantWrapperClass', 'wrapperClass', 'wrapperClassNames.{[],join}'), _dec7 = Ember.computed('overlayPosition'), _dec8 = Ember.computed('targetAttachment'), _dec9 = Ember.computed, _dec(_class = _dec2(_class = (_class2 = class BasicDialog extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "containerClassNames", null);
      (0, _defineProperty2.default)(this, "overlayClassNames", null);
      (0, _defineProperty2.default)(this, "wrapperClassNames", null);
      (0, _defineProperty2.default)(this, "destinationElementId", null);
      (0, _defineProperty2.default)(this, "translucentOverlay", false);
      (0, _defineProperty2.default)(this, "clickOutsideToClose", false);
      (0, _defineProperty2.default)(this, "hasOverlay", true);
      (0, _defineProperty2.default)(this, "isCentered", true);
      (0, _defineProperty2.default)(this, "overlayPosition", null);
      (0, _initializerDefineProperty2.default)(this, "modalService", _descriptor, this);
      (0, _defineProperty2.default)(this, "variantWrapperClass", 'emd-static');
    }

    init() {
      super.init(...arguments);

      if (!this.destinationElementId) {
        Ember.set(this, 'destinationElementId', this.modalService.destinationElementId);
      }
    }

    get containerClassNamesString() {
      let classNames = this.containerClassNames?.join && this.containerClassNames?.join(' ') || this.containerClassNames;
      return ['ember-modal-dialog', classNames, this.targetAttachmentClass, this.attachmentClass, this.containerClass].filter(className => !Ember.isEmpty(className)).join(' ');
    }

    get overlayClassNamesString() {
      let classNames = this.overlayClassNames?.join && this.overlayClassNames?.join(' ') || this.overlayClassNames;
      return ['ember-modal-overlay', classNames, this.translucentOverlay ? 'translucent' : null, this.overlayClass].filter(className => !Ember.isEmpty(className)).join(' ');
    }

    get wrapperClassNamesString() {
      let classNames = this.wrapperClassNames?.join && this.wrapperClassNames?.join(' ') || this.wrapperClassNames;
      return ['ember-modal-wrapper', classNames, this.targetAttachmentClass.replace('emd-', 'emd-wrapper-'), this.variantWrapperClass, this.wrapperClass].filter(className => !Ember.isEmpty(className)).join(' ');
    }

    get isOverlaySibling() {
      return this.overlayPosition === 'sibling';
    }

    get targetAttachmentClass() {
      let targetAttachment = this.targetAttachment || ''; // Convert tether-styled values like 'middle right' to 'right'

      targetAttachment = targetAttachment.split(' ').slice(-1)[0];
      return `ember-modal-dialog-target-attachment-${Ember.String.dasherize(targetAttachment)} emd-target-attachment-${Ember.String.dasherize(targetAttachment)}`;
    }

    didInsertElement() {
      if (!this.clickOutsideToClose) {
        return;
      }

      this.makeOverlayClickableOnIOS();

      this.handleClick = _ref => {
        let {
          target
        } = _ref;

        // if the click has already resulted in the target
        // being removed or hidden, do nothing
        if (target.offsetWidth === 0 && target.offsetHeight === 0) {
          return;
        }

        if (this.isDestroying || this.isDestroyed) {
          return;
        }

        let modalSelector = '.ember-modal-dialog';

        if (this.stack) {
          modalSelector = '#' + this.stack + modalSelector;
        } // if the click is within the dialog, do nothing


        let modalEl = document.querySelector(modalSelector);

        if (modalEl && modalEl.contains(target)) {
          return;
        }

        if (this.onClose) {
          this.onClose();
        }
      };

      const registerClick = () => document.addEventListener('click', this.handleClick); // setTimeout needed or else the click handler will catch the click that spawned this modal dialog


      setTimeout(registerClick);

      if (this.isIOS) {
        const registerTouch = () => document.addEventListener('touchend', this.handleClick);

        setTimeout(registerTouch);
      }

      super.didInsertElement(...arguments);
    }

    willDestroyElement() {
      document.removeEventListener('click', this.handleClick);

      if (this.isIOS) {
        document.removeEventListener('touchend', this.handleClick);
      }

      super.willDestroyElement(...arguments);
    }

    get isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent);
    }

    makeOverlayClickableOnIOS() {
      if (this.isIOS) {
        let overlayEl = document.querySelector('div[data-emd-overlay]');

        if (overlayEl) {
          overlayEl.style.cursor = 'pointer';
        }
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "modalService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "containerClassNamesString", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "containerClassNamesString"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "overlayClassNamesString", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "overlayClassNamesString"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "wrapperClassNamesString", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "wrapperClassNamesString"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isOverlaySibling", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "isOverlaySibling"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "targetAttachmentClass", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "targetAttachmentClass"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isIOS", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "isIOS"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = BasicDialog;
});