define("ember-nrg-ui/components/nrg-list/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qbwScvnn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,5],[[27,[32,1]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,4],null,[[\"header\",\"filter\",\"search\",\"filterChangedAction\"],[[32,0],[30,[36,3],[\"nrg-list/header/filter\"],[[\"changed\"],[[30,[36,1],[[32,0],[35,0]],null]]]],[30,[36,3],[\"nrg-list/header/search\"],[[\"changed\"],[[30,[36,1],[[32,0],[35,2]],null]]]],[30,[36,1],[[32,0],[35,0]],null]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"cleared\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"filterChanged\",\"action\",\"searchChanged\",\"component\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-list/header/template.hbs"
    }
  });

  _exports.default = _default;
});