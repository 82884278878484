define("ember-nrg-ui/helpers/format-currency", ["exports", "ember-nrg-ui/helpers/format-number", "ember-nrg-ui/helpers/unformat"], function (_exports, _formatNumber, _unformat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatCurrency = formatCurrency;

  function formatCurrency(value) {
    const formattedValue = (0, _formatNumber.formatNumber)(value, 2);
    return (0, _unformat.default)(value) < 0 ? '($' + formattedValue + ')' : '$' + formattedValue;
  }

  var _default = Ember.Helper.helper(_ref => {
    let [value] = _ref;
    return formatCurrency(value);
  });

  _exports.default = _default;
});