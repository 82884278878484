define("ember-modal-dialog/templates/components/tether-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "31TzxIbb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[32,0,[\"hasOverlay\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"ember-wormhole\",[],[[\"@to\"],[[32,0,[\"destinationElementId\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[15,0,[32,0,[\"overlayClassNamesString\"]]],[15,\"onclick\",[30,[36,0],[[32,0],[32,0,[\"onClickOverlay\"]]],null]],[14,\"tabindex\",\"-1\"],[14,\"data-emd-overlay\",\"\"],[12],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[8,\"ember-tether\",[],[[\"@class\",\"@target\",\"@attachment\",\"@targetAttachment\",\"@targetModifier\",\"@classPrefix\",\"@offset\",\"@targetOffset\",\"@constraints\"],[[32,0,[\"containerClassNamesString\"]],[32,0,[\"tetherTarget\"]],[32,0,[\"attachment\"]],[32,0,[\"targetAttachment\"]],[32,0,[\"targetModifier\"]],[32,0,[\"tetherClassPrefix\"]],[32,0,[\"offset\"]],[32,0,[\"targetOffset\"]],[32,0,[\"constraints\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"if\"]}",
    "meta": {
      "moduleName": "ember-modal-dialog/templates/components/tether-dialog.hbs"
    }
  });

  _exports.default = _default;
});