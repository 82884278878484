define("ember-nrg-ui/components/nrg-form-field/component", ["exports", "ember-nrg-ui/components/nrg-form-field/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['ui field'],
    classNameBindings: ['required', 'errorMessage:error', 'inline'],
    label: '',
    focusId: '',

    init() {
      this._super(...arguments);

      Ember.run.scheduleOnce('afterRender', this, 'getFocusId');
    },

    getFocusId() {
      const input = this.element.querySelector('input');

      if (input) {
        this.set('focusId', input.id);
      }
    }

  });

  _exports.default = _default;
});