define("ember-nrg-ui/components/nrg-sidebar-menu-item/content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RBY+PEtJ",
    "block": "{\"symbols\":[],\"statements\":[[1,[35,0,[\"sidebarLabel\"]]],[2,\"\\n\"],[6,[37,1],[[35,0,[\"sidebarIconClass\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"i\"],[15,0,[31,[\"right icon \",[34,0,[\"sidebarIconClass\"]]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[35,0,[\"sidebarBadge\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"ui teal label\"],[12],[1,[35,0,[\"sidebarBadge\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"item\",\"if\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-sidebar-menu-item/content/template.hbs"
    }
  });

  _exports.default = _default;
});