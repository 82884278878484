define("ember-nrg-ui/whats-new/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    headerText: "What's New",
    primaryButtonText: "Dismiss",
    secondaryButtonText: '',
    hasContent: Ember.computed.bool('content'),
    isOpen: Ember.computed.reads('hasContent'),

    onModalClose() {// Implemented by user
    },

    onPrimaryClick() {// Implemented by user
    },

    onSecondaryClick() {// Implemented by user
    }

  });

  _exports.default = _default;
});