define("ember-nrg-ui/components/nrg-keyboard-shortcut-modal/special-characters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    default: {
      37: '⇦',
      38: '⇧',
      39: '⇨',
      40: '⇩',
      96: '0',
      97: '1',
      98: '2',
      99: '3',
      100: '4',
      101: '5',
      102: '6',
      103: '7',
      104: '8',
      105: '9',
      186: ';',
      187: '=',
      188: ',',
      189: '-',
      190: '.',
      191: '/',
      192: '`',
      219: '[',
      220: '\\',
      221: ']',
      222: "'"
    },
    shift: {
      37: '⇦',
      38: '⇧',
      39: '⇨',
      40: '⇩',
      96: ')',
      97: '!',
      98: '@',
      99: '#',
      100: '$',
      101: '%',
      102: '^',
      103: '&',
      104: '*',
      105: '(',
      186: ':',
      187: '+',
      188: '<',
      189: '_',
      190: '>',
      191: '?',
      192: '~',
      219: '{',
      220: '|',
      221: '}',
      222: '"'
    }
  };
  _exports.default = _default;
});