define("ember-nrg-ui/mixins/sidebar-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    sidebarMenuManager: Ember.inject.service(),
    sidebarPriority: 10,
    isShownInSidebar: true,
    concatenatedProperties: ['sidebarURLs'],

    init() {
      this._super(...arguments);

      Ember.run.next(() => {
        const sidebarMenuManager = this.get('sidebarMenuManager');
        sidebarMenuManager.registerSidebarMenuItem(this);
        const sidebarURLs = this.get('sidebarURLs') || Ember.A();
        const routeName = this.get('routeName');
        sidebarURLs.forEach(item => {
          if (item.isShownInSidebar === undefined) {
            item.isShownInSidebar = true;
          }

          if (Ember.typeOf(item.isShownInSidebar) === 'string') {
            item.isShownInSidebar = this.get(item.isShownInSidebar);
          }

          sidebarMenuManager.registerSidebarMenuItem(Ember.Object.create({
            sidebarLabel: item.label,
            sidebarURL: item.url,
            sidebarRole: item.role,
            needsAllRoles: item.needsAllRoles,
            sidebarIconClass: item.icon,
            sidebarBadge: item.badge,
            sidebarPriority: item.priority || 10,
            isShownInSidebar: item.isShownInSidebar,
            sidebarFooterItem: item.sidebarFooterItem,
            routeName
          }));
        });
      });
    },

    willDestroy() {
      this._super(...arguments);

      this.get('sidebarMenuManager').unregisterSidebarMenuItem(this);
    }

  });

  _exports.default = _default;
});