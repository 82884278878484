define("ember-nrg-ui/lightbox/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    lightboxIsOpen: false,
    items: Ember.A(),
    selectedItem: null,
    hasChildren: Ember.computed.gt('items.length', 0),

    add(item) {
      this.get('items').pushObject(item);
    },

    remove(thumbnailId) {
      const items = this.get('items').rejectBy('thumbnailId', thumbnailId);
      this.set('items', items);
    },

    selectAndOpen(thumbnailId) {
      const item = this.get('items').findBy('thumbnailId', thumbnailId);
      this.set('selectedItem', item);
      this.set('lightboxIsOpen', true);
    },

    updateDetail(thumbnailId, detail) {
      const item = this.get('items').findBy('thumbnailId', thumbnailId);

      if (item) {
        Ember.set(item, 'detail', detail);
      }
    },

    selectedIndex: Ember.computed('selectedItem', 'items.[]', function () {
      return this.get('items').indexOf(this.get('selectedItem'));
    }),
    previousDisabled: Ember.computed.lte('selectedIndex', 0),
    nextDisabled: Ember.computed('selectedIndex', 'items.[]', function () {
      const selectedIndex = this.get('selectedIndex');
      const totalPhotos = this.get('items.length');
      return selectedIndex === -1 || totalPhotos - 1 === selectedIndex;
    }),

    selectNext() {
      if (!this.get('nextDisabled')) {
        const selectedIndex = this.get('selectedIndex');
        const items = this.get('items');
        const photo = items.objectAt(selectedIndex + 1);
        this.set('selectedItem', photo);
        this.set('rotationClass', '');
      }
    },

    selectPrevious() {
      if (!this.get('previousDisabled')) {
        const selectedIndex = this.get('selectedIndex');
        const items = this.get('items');
        const photo = items.objectAt(selectedIndex - 1);
        this.set('selectedItem', photo);
        this.set('rotationClass', '');
      }
    }

  });

  _exports.default = _default;
});