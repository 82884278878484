define("ember-nrg-ui/components/nrg-master-detail/detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wsImheTt",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"nrg-responsive-takeover\",[],[[\"@onBackArrowClick\",\"@shouldTakeOver\"],[[30,[36,0],[[32,0],[32,0,[\"onBackArrowClick\"]]],null],[32,0,[\"shouldTakeOver\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-master-detail/detail/template.hbs"
    }
  });

  _exports.default = _default;
});