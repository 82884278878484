define("ember-nrg-ui/components/nrg-list/header/search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GgsEo33t",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui icon input\"],[12],[2,\"\\n  \"],[8,\"nrg-text-field\",[],[[\"@class\",\"@placeholder\",\"@value\"],[\"item-list--search\",[34,0],[34,1]]],null],[2,\"\\n  \"],[10,\"i\"],[14,0,\"search link icon\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"placeholder\",\"searchString\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-list/header/search/template.hbs"
    }
  });

  _exports.default = _default;
});