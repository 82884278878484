define("ember-nrg-ui/components/nrg-modal-container/modal-wrapper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n0jDk8qK",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[16,0,[31,[\"ui modal visible active \",[34,6]]]],[16,5,[34,7]],[24,\"role\",\"dialog\"],[4,[38,8],[[30,[36,2],[[32,0],[32,0,[\"modalElementAdded\"]]],null]],null],[12],[2,\"\\n\"],[6,[37,4],[[35,9]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"i\"],[24,0,\"close icon\"],[24,\"role\",\"button\"],[4,[38,2],[[32,0],[35,5]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[35,0,[\"headerText\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"header\"],[12],[2,\"\\n      \"],[1,[35,0,[\"headerText\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[11,\"div\"],[16,0,[34,10]],[4,[38,8],[[30,[36,2],[[32,0],[32,0,[\"addModalToWormhole\"]]],null]],null],[4,[38,11],[[30,[36,2],[[32,0],[32,0,[\"removeModalFromWormhole\"]]],null]],null],[12],[13],[2,\"\\n\\n\"],[6,[37,4],[[35,0,[\"hasButtons\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n\"],[6,[37,4],[[35,0,[\"secondaryButton\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"nrg-button\",[],[[\"@class\",\"@text\",\"@click\"],[[34,0,[\"secondaryButtonClass\"]],[34,0,[\"secondaryButton\"]],[30,[36,2],[[32,0],[35,3]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[35,0,[\"primaryButton\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"nrg-button\",[],[[\"@class\",\"@text\",\"@click\",\"@autofocus\"],[\"primary right\",[34,0,[\"primaryButton\"]],[30,[36,2],[[32,0],[35,1]],null],true]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"modal\",\"_onPrimary\",\"action\",\"_onSecondary\",\"if\",\"onHide\",\"_modalClass\",\"modalStyles\",\"did-insert\",\"hasCloseIcon\",\"_contentClass\",\"will-destroy\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-modal-container/modal-wrapper/template.hbs"
    }
  });

  _exports.default = _default;
});