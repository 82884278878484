define("tracked-toolbox/index", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cached = cached;
  _exports.dedupeTracked = dedupeTracked;
  _exports.localCopy = localCopy;
  _exports.trackedReset = trackedReset;

  var _dec, _class, _descriptor;

  let Meta = (_dec = Ember._tracked, (_class = class Meta {
    constructor() {
      (0, _defineProperty2.default)(this, "prevRemote", void 0);
      (0, _defineProperty2.default)(this, "peek", void 0);
      (0, _initializerDefineProperty2.default)(this, "value", _descriptor, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));

  function getOrCreateMeta(instance, metas, initializer) {
    let meta = metas.get(instance);

    if (meta === undefined) {
      meta = new Meta();
      metas.set(instance, meta);
      meta.value = meta.peek = typeof initializer === 'function' ? initializer.call(instance) : initializer;
    }

    return meta;
  }

  function localCopy(memo, initializer) {
    (true && !(typeof memo === 'string' || typeof memo === 'function') && Ember.assert(`@localCopy() must be given a memo path or memo function as its first argument, received \`${String(memo)}\``, typeof memo === 'string' || typeof memo === 'function'));
    (true && !(typeof memo !== 'function') && Ember.deprecate('Using a memoization function with @localCopy has been deprecated. Consider using @trackedReset instead.', typeof memo !== 'function', {
      id: 'local-copy-memo-fn',
      for: 'tracked-toolbox',
      until: '2.0.0'
    }));
    let metas = new WeakMap();
    return (_prototype, key) => {
      let memoFn = typeof memo === 'function' ? (obj, last) => memo.call(obj, obj, key, last) : obj => Ember.get(obj, memo);
      return {
        get() {
          let meta = getOrCreateMeta(this, metas, initializer);
          let {
            prevRemote
          } = meta;
          let incomingValue = memoFn(this, prevRemote);

          if (prevRemote !== incomingValue) {
            // If the incoming value is not the same as the previous incoming value,
            // update the local value to match the new incoming value, and update
            // the previous incoming value.
            meta.value = meta.prevRemote = incomingValue;
          }

          return meta.value;
        },

        set(value) {
          if (!metas.has(this)) {
            let meta = getOrCreateMeta(this, metas, initializer);
            meta.prevRemote = memoFn(this);
            meta.value = value;
            return;
          }

          getOrCreateMeta(this, metas, initializer).value = value;
        }

      };
    };
  }

  function trackedReset(memoOrConfig) {
    (true && !(typeof memoOrConfig === 'string' || typeof memoOrConfig === 'function' || typeof memoOrConfig === 'object' && memoOrConfig !== null && memoOrConfig.memo !== undefined) && Ember.assert(`@trackedReset() must be given a memo path, a memo function, or config object with a memo path or function as its first argument, received \`${String(memoOrConfig)}\``, typeof memoOrConfig === 'string' || typeof memoOrConfig === 'function' || typeof memoOrConfig === 'object' && memoOrConfig !== null && memoOrConfig.memo !== undefined));
    let metas = new WeakMap();
    return (_prototype, key, desc) => {
      let memo, update;

      let initializer = desc.initializer ?? (() => undefined);

      if (typeof memoOrConfig === 'object') {
        memo = memoOrConfig.memo;
        update = memoOrConfig.update ?? initializer;
      } else {
        memo = memoOrConfig;
        update = initializer;
      }

      let memoFn = typeof memo === 'function' ? (obj, last) => memo.call(obj, obj, key, last) : obj => Ember.get(obj, memo);
      return {
        get() {
          let meta = getOrCreateMeta(this, metas, initializer);
          let {
            prevRemote
          } = meta;
          let incomingValue = memoFn(this, prevRemote);

          if (incomingValue !== prevRemote) {
            meta.prevRemote = incomingValue;
            meta.value = meta.peek = update.call(this, this, key, meta.peek);
          }

          return meta.value;
        },

        set(value) {
          getOrCreateMeta(this, metas, initializer).value = value;
        }

      };
    };
  }

  function cached(target, key, value) {
    (true && !(value && value.get) && Ember.assert('@cached can only be used on getters', value && value.get));
    let {
      get,
      set
    } = value;
    let caches = new WeakMap();
    return {
      get() {
        let cache = caches.get(this);

        if (cache === undefined) {
          cache = Ember._createCache(get.bind(this));
          caches.set(this, cache);
        }

        return Ember._cacheGetValue(cache);
      },

      set
    };
  }

  function dedupeTracked(target, key, desc) {
    let {
      initializer
    } = desc;

    let {
      get,
      set
    } = Ember._tracked(target, key, desc);

    let values = new WeakMap();
    return {
      get() {
        if (!values.has(this)) {
          let value = initializer?.call(this);
          values.set(this, value);
          set.call(this, value);
        }

        return get.call(this);
      },

      set(value) {
        if (!values.has(this) || value !== values.get(this)) {
          values.set(this, value);
          set.call(this, value);
        }
      }

    };
  }
});