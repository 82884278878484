define("ember-cli-mirage/utils/read-modules", ["exports", "ember-cli-mirage/assert", "ember-cli-mirage/utils/inflector", "require"], function (_exports, _assert, _inflector, _require) {
  /* global requirejs */

  /* eslint-env node */
  'use strict';

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /**
    This function looks through all files that have been loaded by Ember CLI and
    finds the ones under /mirage/[factories, fixtures, scenarios, models]/, and exports
    a hash containing the names of the files as keys and the data as values.
  
    @hide
  */
  function _default(prefix) {
    let modules = ['factories', 'fixtures', 'scenarios', 'models', 'serializers', 'identity-managers'];
    let mirageModuleRegExp = new RegExp(`^${prefix}/mirage/(${modules.join('|')})`);
    let modulesMap = modules.reduce((memo, name) => {
      memo[(0, _inflector.camelize)(name)] = {};
      return memo;
    }, {});
    Object.keys(requirejs.entries).filter(function (key) {
      return mirageModuleRegExp.test(key);
    }).forEach(function (moduleName) {
      if (moduleName.match('.jshint')) {
        // ignore autogenerated .jshint files
        return;
      }

      let moduleParts = moduleName.split('/');
      let moduleTypeIndex = moduleParts.indexOf('mirage') + 1;
      let moduleType = (0, _inflector.camelize)(moduleParts[moduleTypeIndex]);
      let moduleKey = moduleParts.slice([moduleTypeIndex + 1]).join('/');

      if (moduleType === 'scenario') {
        (0, _assert.default)('Only scenario/default.js is supported at this time.', moduleKey !== 'default');
      }
      /*
        Ensure fixture keys are pluralized
      */


      if (moduleType === 'fixtures') {
        moduleKey = (0, _inflector.pluralize)(moduleKey);
      }

      let module = (0, _require.default)(moduleName, null, null, true);

      if (!module) {
        throw new Error(`${moduleName} must export a ${moduleType}`);
      }

      let data = module.default;
      modulesMap[moduleType][(0, _inflector.camelize)(moduleKey)] = data;
    });
    return modulesMap;
  }
});