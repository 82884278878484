define("ember-nrg-ui/helpers/list-group-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.exists = exists;

  function exists(_ref) {
    let [items, i, groupHeaderHandler] = _ref;
    const item = Ember.A(items).objectAt(i);

    if (i === 0) {
      return groupHeaderHandler(item);
    }

    const previousItem = Ember.A(items).objectAt(i - 1);
    const previousGroupHeaderLabel = groupHeaderHandler(previousItem);
    const label = groupHeaderHandler(item);

    if (previousGroupHeaderLabel !== label) {
      return label;
    }

    return '';
  }

  var _default = Ember.Helper.helper(exists);

  _exports.default = _default;
});