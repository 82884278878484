define("ember-nrg-ui/mixins/resize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    resizeService: Ember.inject.service('resize'),
    responsive: Ember.inject.service(),
    resizeEventsEnabled: true,
    resizeDebouncedEventsEnabled: true,
    screenWidth: Ember.computed.readOnly('responsive.screenWidth'),
    screenHeight: Ember.computed.readOnly('responsive.screenHeight'),

    didInsertElement() {
      this._super(...arguments);

      if (this.get('resizeEventsEnabled')) {
        this.get('resizeService').on('didResize', this, this._handleResizeEvent);
      }

      if (this.get('resizeDebouncedEventsEnabled')) {
        this.get('resizeService').on('debouncedDidResize', this, this._handleDebouncedResizeEvent);
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      if (this.get('resizeEventsEnabled')) {
        this.get('resizeService').off('didResize', this, this._handleResizeEvent);
      }

      if (this.get('resizeDebouncedEventsEnabled')) {
        this.get('resizeService').off('debouncedDidResize', this, this._handleDebouncedResizeEvent);
      }
    },

    didResize() {// Overridden in subclass
    },

    debouncedDidResize() {// Overridden in subclass
    },

    _handleResizeEvent(evt) {
      const {
        innerWidth,
        innerHeight
      } = window;
      this.didResize(innerWidth, innerHeight, evt);
    },

    _handleDebouncedResizeEvent(evt) {
      const {
        innerWidth,
        innerHeight
      } = window;
      this.debouncedDidResize(innerWidth, innerHeight, evt);
    }

  });

  _exports.default = _default;
});