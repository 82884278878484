define("ember-nrg-ui/components/nrg-datetime/component", ["exports", "ember-nrg-ui/components/nrg-datetime/template", "moment", "ember-nrg-ui/mixins/validation"], function (_exports, _template, _moment, _validation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validation.default, {
    layout: _template.default,
    tagName: '',
    classNames: [],
    type: 'date',
    // 'datetime', 'date', 'time'
    showNowShortcut: true,
    isFocused: false,
    minDate: null,
    // minimum date/time that can be selected, dates/times before are disabled
    maxDate: null,
    // maximum date/time that can be selected, dates/times after are disabled
    initializeDate: true,

    init() {
      this._super(...arguments);

      if (!this.value && this.initializeDate) {
        this.set('value', new Date());
      }
    },

    icon: Ember.computed('type', function () {
      const type = this.get('type');
      let icon = 'calendar';

      if (type === 'time') {
        icon = 'clock';
      }

      return icon;
    }),

    onBlur() {
      this.set('isFocused', false);
    },

    onFocus(evt) {
      if (this.isFocused) {
        return;
      }

      const wrapper = evt.currentTarget;
      Ember.run.next(() => {
        const popup = wrapper && wrapper.querySelector('.ui.popup.calendar');

        if (popup) {
          popup.focus();
        }
      });
      this.set('isFocused', true);
    },

    onCalendarClose() {
      this.set('isFocused', false);
    },

    isDateDisabled() {},

    dateFormat: 'LL',
    timeFormat: 'LT',
    displayFormat: Ember.computed('dateFormat', 'timeFormat', 'type', function () {
      if (this.type === 'datetime') {
        return `${this.dateFormat} ${this.timeFormat}`;
      } else if (this.type === 'date') {
        return this.dateFormat;
      }

      return this.timeFormat;
    }),
    displayValue: Ember.computed('value', 'displayFormat', 'initializeDate', {
      get() {
        if (!this.value) {
          return '';
        }

        return (0, _moment.default)(this.value).format(this.displayFormat);
      },

      set(type, value) {
        const newValue = (0, _moment.default)(value, this.displayFormat);

        if (newValue.isValid()) {
          this.set('value', newValue);
        }

        return value;
      }

    }),

    onDateSelect(value) {
      this.set('isFocused', false);
      this.set('value', value);
    }

  });

  _exports.default = _default;
});