define("ember-nrg-ui/modifiers/on-click-outside", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember._setModifierManager(owner => ({
    createModifier() {
      return {
        element: null,
        clickHandler: null,
        disabled: false
      };
    },

    installModifier(state, element, args) {
      const [callback] = args.positional;
      const {
        disabled
      } = args.named;
      const guid = Ember.guidFor(element);

      const clickHandler = function (event) {
        const foundTargetInWrapper = event.target.closest(`[data-click-handler=${guid}]`);

        if (!foundTargetInWrapper && !owner.isDestroying && !state.disabled) {
          callback();
        }

        return false;
      };

      state.element = element;
      state.clickHandler = clickHandler;
      state.disabled = disabled;
      element.dataset.clickHandler = guid;
      document.documentElement.addEventListener('click', clickHandler, true);
    },

    updateModifier(state, args) {
      const {
        disabled
      } = args.named;
      state.disabled = disabled;
    },

    destroyModifier(state) {
      if (state.element) {
        delete state.element.dataset.clickHandler;
      }

      document.documentElement.removeEventListener('click', state.clickHandler, true);
      state.clickHandler = null;
    }

  }), class OnClickOutsideModifer {});

  _exports.default = _default;
});