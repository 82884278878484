define("ember-nrg-ui/components/nrg-application-error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XA1rf7dg",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui piled red segment text-center\"],[12],[2,\"\\n  \"],[10,\"h3\"],[12],[2,\"An error occurred.\"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"Please reload the page.\"],[13],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\",\"@class\"],[\"index\",\"ui primary button\"]],[[\"default\"],[{\"statements\":[[2,\"Home\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-application-error/template.hbs"
    }
  });

  _exports.default = _default;
});