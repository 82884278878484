define("ember-basic-dropdown-hover/templates/components/basic-dropdown-hover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/EjnS6j0",
    "block": "{\"symbols\":[\"dd\",\"&default\"],\"statements\":[[6,[37,15],null,[[\"renderInPlace\",\"verticalPosition\",\"horizontalPosition\",\"matchTriggerWidth\",\"triggerComponent\",\"contentComponent\",\"calculatePosition\",\"onOpen\",\"onClose\",\"onFocus\",\"calculateInPlacePosition\"],[[35,14],[35,13],[35,12],[35,11],[35,10],[35,9],[35,8],[35,7],[35,6],[35,5],[35,4]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[18,2,[[30,[36,3],[[32,1],[30,[36,2],null,[[\"trigger\",\"content\"],[[30,[36,1],[[32,1,[\"trigger\"]]],[[\"onMouseDown\",\"onMouseEnter\",\"onMouseLeave\"],[[30,[36,0],[[32,0],\"prevent\"],null],[30,[36,0],[[32,0],\"open\"],null],[30,[36,0],[[32,0],\"close\"],null]]]],[30,[36,1],[[32,1,[\"content\"]]],[[\"onMouseEnter\",\"onMouseLeave\"],[[30,[36,0],[[32,0],\"open\"],null],[30,[36,0],[[32,0],\"close\"],null]]]]]]]],null]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"hash\",\"assign\",\"calculateInPlacePosition\",\"onFocus\",\"onClose\",\"onOpen\",\"calculatePosition\",\"contentComponent\",\"triggerComponent\",\"matchTriggerWidth\",\"horizontalPosition\",\"verticalPosition\",\"renderInPlace\",\"basic-dropdown\"]}",
    "meta": {
      "moduleName": "ember-basic-dropdown-hover/templates/components/basic-dropdown-hover.hbs"
    }
  });

  _exports.default = _default;
});