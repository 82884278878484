define("ember-nrg-ui/components/nrg-text-field/component", ["exports", "ember-nrg-ui/components/nrg-text-field/template", "ember-nrg-ui/mixins/validation"], function (_exports, _template, _validation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validation.default, {
    layout: _template.default,
    classNames: ['ui', 'input'],
    classNameBindings: ['fluid'],
    type: 'text',

    input() {
      this.sendAction('action', this.get('value'));
    },

    onFocus() {},

    onBlur() {},

    onChange() {}

  });

  _exports.default = _default;
});