define("ember-nrg-ui/components/nrg-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LGj8lKle",
    "block": "{\"symbols\":[],\"statements\":[[8,\"input\",[],[[\"@id\",\"@class\",\"@type\",\"@checked\",\"@name\",\"@disabled\",\"@readonly\",\"@change\"],[[34,0],\"hidden\",\"checkbox\",[34,1],[34,2],[34,3],[34,4],[30,[36,6],[[32,0],[35,5]],null]]],null],[2,\"\\n\"],[10,\"label\"],[15,\"for\",[34,0]],[12],[1,[34,7]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"inputId\",\"_checked\",\"name\",\"disabled\",\"readonly\",\"handleValueChange\",\"action\",\"label\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});