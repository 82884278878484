define("ember-nrg-ui/release-notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1GnOBUjh",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui segment markdown-body\"],[12],[10,\"h1\"],[12],[2,\"No Release Notes Available\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-nrg-ui/release-notes/template.hbs"
    }
  });

  _exports.default = _default;
});