define("ember-nrg-ui/components/nrg-icon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'i',
    classNames: ['icon'],
    classNameBindings: ['icon']
  });

  _exports.default = _default;
});