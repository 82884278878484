define("ember-modal-dialog/helpers/ignore-children", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function ignoreChildren(_ref) {
    let [nextHandler] = _ref;
    return function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      let event = args[args.length - 1];

      if (event && event.target === event.currentTarget) {
        nextHandler.apply(this, args);
      }
    };
  });

  _exports.default = _default;
});