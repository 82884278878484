define("ember-nrg-ui/components/nrg-form-action/component", ["exports", "ember-nrg-ui/components/nrg-form-action/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'a',
    classNames: ['item', 'form-link'],

    click(evt) {
      evt.preventDefault();
      this.sendAction();
    }

  });

  _exports.default = _default;
});