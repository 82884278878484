define("ember-nrg-ui/components/nrg-appbar/component", ["exports", "ember-nrg-ui/mixins/resize", "ember-nrg-ui/components/nrg-appbar/template"], function (_exports, _resize, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_resize.default, {
    layout: _template.default,
    title: 'App Title',
    classNames: ['ui', 'menu', 'main', 'fixed', 'inverted'],
    router: Ember.inject.service(),
    isMobileScreen: Ember.computed.alias('responsive.isMobileScreenGroup'),
    showReleaseNotes: true,
    environmentDisplay: Ember.computed('applicationSettings.localEnvironment', function () {
      const ENV = Ember.getOwner(this).resolveRegistration('config:environment');
      const config = ENV['ember-nrg-ui'];
      const productionEnvironments = config && config.productionEnvironments || ['prod'];
      const environment = this.get('applicationSettings.localEnvironment');

      if (environment && !productionEnvironments.includes(environment)) {
        return environment.toUpperCase();
      }

      return null;
    }),

    onToggleSidebar() {
      this.sendAction('toggleSidebar');
    },

    onBackArrowClick() {// Implement
    }

  });

  _exports.default = _default;
});