define("ember-nrg-ui/components/nrg-dropdown/sub-menu/component", ["exports", "ember-nrg-ui/components/nrg-dropdown/sub-menu/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['item'],
    classNameBindings: ['disabled', 'active'],
    attributeBindings: ['data-dropdown-item'],
    dataDropdownItem: 'true',
    isOpen: false,
    menuClass: Ember.computed('menuDirection', 'isOpen', function () {
      let computedClasses = '';

      if (this.menuDirection) {
        computedClasses += ` ${this.menuDirection}`;
      }

      if (this.isOpen) {
        computedClasses += ' transition visible';
      } else {
        computedClasses += ' transition hidden';
      }

      return computedClasses;
    }),

    mouseEnter() {
      this.set('isOpen', true);
    },

    mouseLeave() {
      this.set('isOpen', false);
    },

    _onSelect() {// Implemented by dropdown
    }

  });

  _exports.default = _default;
});