define("ember-nrg-ui/initializers/config-override", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(application) {
    const config = application.resolveRegistration('config:environment');

    if (!config.flashMessageDefaults) {
      config.flashMessageDefaults = {
        timeout: 7000,
        type: 'info',
        types: ['success', 'error', 'info', 'warning'],
        showProgress: true
      };
    }

    if (!config.moment) {
      config.moment = {
        allowEmpty: true,
        includeTimezone: 'all'
      };
    }
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});