define("ember-nrg-ui/sidebar-menu-manager/service", ["exports", "ember-nrg-ui/mixins/sidebar-navigation"], function (_exports, _sidebarNavigation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    routing: Ember.inject.service('-routing'),

    init() {
      this._super(...arguments);

      this.loadApplicableRoutes();
    },

    availableRoutes: Ember.computed(function () {
      const availableRoutes = this.get('routing.router._routerMicrolib.recognizer.names');
      return availableRoutes && Object.keys(availableRoutes) || [];
    }),

    loadApplicableRoutes() {
      const routing = this.get('routing');
      const availableRoutes = this.get('availableRoutes');
      const container = Ember.getOwner(this);
      availableRoutes.forEach(routeName => {
        const route = container.lookup(`route:${routeName}`);

        const isSidebarRoute = route && _sidebarNavigation.default.detect(route);

        if (isSidebarRoute) {
          routing.generateURL(routeName, Ember.A(), {});
        }
      });
    },

    registerSidebarMenuItem(menuItem) {
      this.get('_menuItems').pushObject(menuItem);
    },

    unregisterSidebarMenuItem(menuItem) {
      this.get('_menuItems').removeObject(menuItem);
    },

    contextItemSort: ['sidebarPriority:desc', 'sidebarLabel:asc'],
    _menuItems: Ember.A(),
    _menuItemsRoleFiltered: Ember.computed('_menuItems.[]', 'currentUser.roles.[]', function () {
      return this.get('_menuItems').filter(item => {
        const currentUserContent = this.get('currentUser.content');

        if (!item.sidebarRole) {
          return true;
        }

        if (!currentUserContent) {
          return false;
        }

        const roles = Array.isArray(item.sidebarRole) ? item.sidebarRole : [item.sidebarRole];

        if (item.needsAllRoles) {
          return roles.every(role => currentUserContent.hasRole(role));
        }

        return roles.some(role => currentUserContent.hasRole(role));
      });
    }),
    _menuItemsSorted: Ember.computed.sort('_menuItemsRoleFiltered', 'contextItemSort'),
    footerMenuItems: Ember.computed.filterBy('_menuItemsSorted', 'sidebarFooterItem', true),
    _menuItemsFiltered: Ember.computed.setDiff('_menuItemsSorted', 'footerMenuItems'),
    _groupMenuItems: Ember.computed('_menuItemsFiltered.[]', function () {
      const menuItems = this.get('_menuItemsFiltered');
      const menuGroups = menuItems.filter(menuItem => {
        return menuItem.isSidebarGroupHeader;
      });
      menuGroups.forEach(menuGroup => {
        if (menuGroup.routeName === 'application') {
          Ember.set(menuGroup, 'isApplicationRoute', true);
          return;
        }

        const children = menuItems.filter(menuItem => {
          if (!menuItem.sidebarAction && (!menuItem.routeName || menuItem.isSidebarGroupHeader)) {
            return false;
          }

          let isChild = false;

          if (menuItem.sidebarAction) {
            isChild = menuItem.sidebarGroup === menuGroup.sidebarGroup;
          } else {
            const belongsToRoute = menuItem.routeName.indexOf(menuGroup.routeName) === 0;
            const isSameRoute = menuGroup.routeName === menuItem.routeName && !menuItem.sidebarURL;
            isChild = belongsToRoute && !isSameRoute;
          }

          if (isChild) {
            menuItem.isChild = isChild;
          }

          return isChild;
        });
        Ember.set(menuGroup, 'children', children);
      });
      const orphanRoutes = menuItems.filter(menuItem => {
        return !menuItem.isChild && !menuItem.isSidebarGroupHeader;
      });
      return menuGroups.concat(orphanRoutes);
    }),
    menuItems: Ember.computed.sort('_groupMenuItems', 'contextItemSort')
  });

  _exports.default = _default;
});