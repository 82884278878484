define("ember-nrg-ui/components/nrg-new-features/component", ["exports", "ember-nrg-ui/components/nrg-new-features/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    whatsNewService: Ember.inject.service('whats-new'),
    _isOpen: Ember.computed.and('whatsNewService.isOpen', 'canOpen'),
    headerText: Ember.computed.alias('whatsNewService.headerText'),
    primaryButtonText: Ember.computed.alias('whatsNewService.primaryButtonText'),
    secondaryButtonText: Ember.computed.alias('whatsNewService.secondaryButtonText'),
    htmlContent: Ember.computed('content', function () {
      return Ember.String.htmlSafe(this.content);
    }),

    didInsertElement() {
      this._super(...arguments);

      this.set('canOpen', true);
    },

    content: Ember.computed.alias('whatsNewService.content'),

    closeModal() {
      this.set('whatsNewService.isOpen', false);
    },

    onModalClose() {
      this.whatsNewService.onModalClose();
    },

    onPrimaryClick() {
      this.whatsNewService.onPrimaryClick();
      this.closeModal();
    },

    onSecondaryClick() {
      this.whatsNewService.onSecondaryClick();
      this.closeModal();
    }

  });

  _exports.default = _default;
});