define('ember-keyboard/utils/trigger-event', ['exports', 'ember-keyboard/utils/get-cmd-key', 'ember-keyboard', 'ember-keyboard/fixtures/modifiers-array'], function (exports, _getCmdKey, _emberKeyboard, _modifiersArray) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.triggerKeyUp = exports.triggerKeyPress = exports.triggerKeyDown = undefined;


  const triggerKeyEvent = function triggerKeyEvent(eventType, rawCode, element) {
    const event = new Event(eventType);
    const parts = rawCode.split('+');
    const [code] = parts.filter(part => !_modifiersArray.default.includes(part));
    const modifiers = parts.filter(part => part !== code);
    const properties = modifiers.reduce((properties, modifier) => {
      modifier = modifier === 'cmd' ? (0, _getCmdKey.default)() : modifier;
      properties[`${modifier}Key`] = true;

      return properties;
    }, {});

    Ember.assign(event, { code, keyCode: (0, _emberKeyboard.getKeyCode)(code) }, properties);

    (element || document).dispatchEvent(event);
  };

  const triggerKeyDown = function triggerKeyDown(code, element) {
    triggerKeyEvent('keydown', code, element);
  };

  const triggerKeyPress = function triggerKeyPress(code, element) {
    triggerKeyEvent('keypress', code, element);
  };

  const triggerKeyUp = function triggerKeyUp(code, element) {
    triggerKeyEvent('keyup', code, element);
  };

  exports.triggerKeyDown = triggerKeyDown;
  exports.triggerKeyPress = triggerKeyPress;
  exports.triggerKeyUp = triggerKeyUp;
});