define("ember-nrg-ui/helpers/exists", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.exists = exists;

  function exists(_ref) {
    let [array, item] = _ref;
    return !Ember.isEmpty(array) && item && array.includes(item) || false;
  }

  var _default = Ember.Helper.helper(exists);

  _exports.default = _default;
});