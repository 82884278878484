define("ember-nrg-ui/components/nrg-home-cards/component", ["exports", "ember-nrg-ui/components/nrg-home-cards/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    columns: 'three',
    classNameBindings: ['columns'],
    classNames: ['ui', 'cards'],
    actionCard: false
  });

  _exports.default = _default;
});