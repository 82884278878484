define("ember-modal-dialog/components/in-place-dialog", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@ember-decorators/component", "ember-modal-dialog/templates/components/in-place-dialog"], function (_exports, _defineProperty2, _component, _inPlaceDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  let InPlaceDialog = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_inPlaceDialog.default), _dec(_class = _dec2(_class = class InPlaceDialog extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "containerClass", null);
    }

    // passed in
    init() {
      super.init(...arguments);
      this.containerClassNames = ['ember-modal-dialog', 'ember-modal-dialog-in-place', 'emd-in-place']; // set this in a subclass definition
    }

    get containerClassNamesString() {
      return this.containerClassNames?.join && this.containerClassNames?.join(' ') || this.containerClassNames || '';
    }

  }) || _class) || _class);
  _exports.default = InPlaceDialog;
});