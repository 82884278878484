define("ember-nrg-ui/components/nrg-sidebar/component", ["exports", "ember-nrg-ui/mixins/resize", "ember-nrg-ui/components/nrg-sidebar/template"], function (_exports, _resize, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_resize.default, {
    layout: _template.default,
    isLargeScreen: Ember.computed.alias('responsive.isComputerScreenGroup'),

    didResize() {
      if (this.get('isOpen') && this.get('isLargeScreen')) {
        this.set('isOpen', false);
      }
    },

    clickedLink(item) {
      this.set('isOpen', false);

      if (this.clickedSidebarItem) {
        this.clickedSidebarItem(item);
      }
    }

  });

  _exports.default = _default;
});