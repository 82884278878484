define("ember-nrg-ui/components/nrg-popup/popup/component", ["exports", "ember-nrg-ui/components/nrg-popup/popup/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TARGET_POSITIONS = {
    top: 'top left',
    bottom: 'bottom left',
    left: 'middle left',
    right: 'middle right'
  };
  const POPUP_POSITIONS = {
    top: 'bottom left',
    bottom: 'top left',
    left: 'middle right',
    right: 'middle left'
  };

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    position: 'top',
    targetAttachment: Ember.computed('position', function () {
      return TARGET_POSITIONS[this.position];
    }),
    popupAttachment: Ember.computed('position', function () {
      return POPUP_POSITIONS[this.position];
    }),
    popupClass: '',
    _popupClass: Ember.computed('targetAttachment', 'inverted', 'popupClass', function () {
      let appliedClass = '';

      if (this.targetAttachment) {
        appliedClass += this.targetAttachment.replace('middle', 'center');
      }

      if (this.inverted) {
        appliedClass += ' inverted';
      }

      if (this.popupClass) {
        appliedClass += ` ${this.popupClass}`;
      }

      return appliedClass;
    }),
    popupContainer: Ember.computed(function () {
      return document.querySelector('#popup-container');
    }),

    onMouseEnter() {// implement
    },

    onMouseLeave() {// implement
    }

  });

  _exports.default = _default;
});