define("ember-nrg-ui/components/nrg-lightbox-container/component", ["exports", "ember-nrg-ui/components/nrg-lightbox-container/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    lightboxService: Ember.inject.service('lightbox'),
    isOpen: Ember.computed.alias('lightboxService.lightboxIsOpen'),
    selectedItem: Ember.computed.alias('lightboxService.selectedItem'),
    selectedPhoto: Ember.computed.alias('selectedItem.photo'),
    selectedPhotoDetail: Ember.computed('selectedItem.detail', function () {
      return Ember.String.htmlSafe(this.get('selectedItem.detail'));
    }),
    rotationClass: Ember.computed.alias('lightboxService.rotationClass'),
    previousDisabled: Ember.computed.alias('lightboxService.previousDisabled'),
    nextDisabled: Ember.computed.alias('lightboxService.nextDisabled'),

    onModalOpen() {
      this.set('rotationClass', '');
    },

    previousImage() {
      this.get('lightboxService').selectPrevious();
    },

    nextImage() {
      this.get('lightboxService').selectNext();
    },

    toggleDetailLocation() {
      this.toggleProperty('bottomDetails');
    },

    rotateLeft() {
      const rotationClass = this.get('rotationClass');

      if (!rotationClass) {
        this.set('rotationClass', 'rotate-left');
      }

      if (rotationClass === 'rotate-left') {
        this.set('rotationClass', 'rotate-down');
      }

      if (rotationClass === 'rotate-down') {
        this.set('rotationClass', 'rotate-right');
      }

      if (rotationClass === 'rotate-right') {
        this.set('rotationClass', '');
      }
    },

    rotateRight() {
      const rotationClass = this.get('rotationClass');

      if (!rotationClass) {
        this.set('rotationClass', 'rotate-right');
      }

      if (rotationClass === 'rotate-right') {
        this.set('rotationClass', 'rotate-down');
      }

      if (rotationClass === 'rotate-down') {
        this.set('rotationClass', 'rotate-left');
      }

      if (rotationClass === 'rotate-left') {
        this.set('rotationClass', '');
      }
    }

  });

  _exports.default = _default;
});