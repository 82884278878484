define("ember-nrg-ui/components/nrg-dropdown/menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/3bwYo5/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[[34,1],\" \",[34,0],\" menu\"]]],[12],[2,\"\\n  \"],[18,1,[[30,[36,9],null,[[\"options\",\"item\",\"sub-menu\"],[[35,8],[30,[36,4],[\"nrg-dropdown/item\"],[[\"active\",\"_onSelect\"],[[30,[36,7],[[35,6],[35,5]],null],[30,[36,3],[[32,0],[35,2]],null]]]],[30,[36,4],[\"nrg-dropdown/sub-menu\"],[[\"_onSelect\"],[[30,[36,3],[[32,0],[35,2]],null]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"scrollableClass\",\"menuClass\",\"_onSelect\",\"action\",\"component\",\"activeItem\",\"i\",\"eq\",\"displayedOptions\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-dropdown/menu/template.hbs"
    }
  });

  _exports.default = _default;
});