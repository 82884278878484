define("ember-nrg-ui/mixins/validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init() {
      this._super(...arguments);

      const valuePath = this.get('valuePath');
      Ember.defineProperty(this, 'validation', Ember.computed.oneWay(`model.validations.attrs.${valuePath}`));
      const {
        value,
        model
      } = this.getProperties('value', 'model');

      if (Ember.isNone(value) && !Ember.isNone(model)) {
        Ember.defineProperty(this, 'value', Ember.computed.alias(`model.${valuePath}`));
      }

      Ember.run.next(this, () => {
        if (!this.get('isDestroyed') && this.get('valuePath')) {
          this.propogateErrorMessage();
        }
      });
    },

    errorMessage: Ember.computed.oneWay('validation.message'),
    didValidate: Ember.computed.alias('field.form.didValidate'),
    hasContent: Ember.computed.notEmpty('value'),
    isValid: Ember.computed.and('hasContent', 'validation.isTruelyValid'),
    isInvalid: Ember.computed.oneWay('validation.isInvalid'),
    hasWarnings: Ember.computed.oneWay('validation.hasWarnings'),
    showError: Ember.computed('validation.isDirty', 'isInvalid', 'didValidate', function () {
      return this.get('didValidate') && this.get('isInvalid');
    }),
    showWarning: Ember.computed('validation.isDirty', 'hasWarnings', 'showError', function () {
      return this.get('hasWarnings') && !this.get('showError');
    }),
    errorMessageObserver: Ember.observer('validation.message', 'validation.isDirty', 'isInvalid', 'didValidate', function () {
      Ember.run.throttle(this, this.propogateErrorMessage, 50, false);
    }),

    propogateErrorMessage() {
      if (this.get('isDestroyed') || !this.get('valuePath')) {
        return;
      }

      let errorMessage = '';
      let warningMessage = '';

      if (this.get('showError')) {
        errorMessage = this.get('validation.message');
      }

      if (this.get('showWarning')) {
        warningMessage = this.get('validation.warningMessage');
      }

      if (this.get('field')) {
        this.set('field.errorMessage', errorMessage);
        this.set('field.warningMessage', warningMessage);
      }
    }

  });

  _exports.default = _default;
});