define("ember-nrg-ui/components/nrg-list/header/filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FG29/LzZ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"nrg-dropdown\",[],[[\"@class\",\"@menuDirection\",\"@direction\",\"@defaultText\",\"@selected\",\"@options\"],[[34,0],[34,1],[34,2],[34,3],[34,4],[34,5]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"dropdownClass\",\"menuDirection\",\"direction\",\"defaultText\",\"selectedFilter\",\"filters\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-list/header/filter/template.hbs"
    }
  });

  _exports.default = _default;
});