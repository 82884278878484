define("ember-nrg-ui/components/nrg-appbar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ffwi9Q+H",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"ui fluid container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"left menu\"],[12],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[11,\"a\"],[24,\"role\",\"button\"],[24,0,\"ui item icon sidebar-toggle-button\"],[4,[38,2],[[32,0],[32,0,[\"onBackArrowClick\"]]],null],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"arrow left icon\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[11,\"a\"],[24,\"role\",\"button\"],[24,0,\"ui item icon sidebar-toggle-button\"],[4,[38,2],[[32,0],[32,0,[\"onToggleSidebar\"]]],null],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"content icon\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"div\"],[14,0,\"header item title-item\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"title-text\"],[12],[1,[34,5]],[13],[2,\"\\n\"],[6,[37,4],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"environment-title\"],[12],[1,[34,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"right menu\"],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n\"],[6,[37,4],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"item\"],[12],[2,\"\\n        \"],[8,\"link-to\",[],[[\"@route\"],[\"release-notes\"]],[[\"default\"],[{\"statements\":[[1,[34,0]]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[8,\"nrg-context-menu\",[],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"nrg-app-version\",\"environmentDisplay\",\"action\",\"showBackArrow\",\"if\",\"title\",\"showReleaseNotes\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-appbar/template.hbs"
    }
  });

  _exports.default = _default;
});