define("ember-nrg-ui/components/nrg-toast/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2ks1xFx9",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"showProgress\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"ui attached active progress \",[32,0,[\"toast\",\"type\"]],\" inverted top compact\"]]],[12],[2,\"\\n\"],[2,\"    \"],[10,\"div\"],[14,0,\"bar down progressing pausable\"],[15,5,[32,0,[\"progressDuration\"]]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[15,0,[31,[[32,0,[\"toast\",\"type\"]],\" ui toast compact visible\"]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui header\"],[12],[1,[32,0,[\"toast\",\"title\"]]],[13],[2,\"\\n    \"],[10,\"div\"],[12],[1,[32,0,[\"toast\",\"message\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-toast/template.hbs"
    }
  });

  _exports.default = _default;
});