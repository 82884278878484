define("ember-nrg-ui/components/nrg-list/component", ["exports", "ember-nrg-ui/components/nrg-list/template", "ember-nrg-ui/mixins/context-menu"], function (_exports, _template, _contextMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const contextItems = [{
    label: 'Refresh',
    actionName: 'signalRefresh',
    iconClass: 'refresh'
  }];
  const DEFAULT_PAGE_SIZE = 25;

  var _default = Ember.Component.extend(_contextMenu.default, {
    layout: _template.default,
    tagName: '',
    hasRefreshContextItem: true,
    filters: null,
    filterParam: null,
    searchString: '',
    searchParameter: '',
    selectedPageSize: DEFAULT_PAGE_SIZE,
    selectionType: '',

    init() {
      if (this.get('hasRefreshContextItem')) {
        this.contextItems = contextItems;
      }

      this._super(...arguments);
    },

    updateQuery() {
      let actionName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'query';
      const query = {};
      const searchParam = this.get('searchParameter') || 'search';
      const searchString = this.get('searchString');
      const selectedFilterValue = this.get('selectedFilter.value');
      const filterParam = this.get('filterParam');

      if (!Ember.isBlank(searchString)) {
        query[searchParam] = searchString;
      }

      if (!Ember.isBlank(selectedFilterValue) && !Ember.isBlank(filterParam)) {
        query[this.get('filterParam')] = selectedFilterValue;
      }

      Ember.run.next(() => {
        this.sendAction(actionName, query);
      });
    },

    isSelectable() {
      return true;
    },

    filterChanged(filterParam, selectedFilter) {
      this.setProperties({
        filterParam,
        selectedFilter
      });
      this.updateQuery();
    },

    searchChanged(searchString) {
      this.set('searchString', searchString);
      this.updateQuery();
    },

    onItemSelect(item) {
      this.sendAction('select', item);
    },

    onChangePage(start) {
      this.sendAction('changePage', start);
    },

    actions: {
      signalRefresh() {
        this.updateQuery('refresh');
      }

    }
  });

  _exports.default = _default;
});