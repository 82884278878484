define("ember-nrg-ui/components/nrg-text-area/component", ["exports", "ember-nrg-ui/components/nrg-text-area/template", "ember-nrg-ui/mixins/validation"], function (_exports, _template, _validation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validation.default, {
    layout: _template.default,
    classNames: ['ui', 'fluid', 'input', 'nrg-text-area'],
    classNameBindings: ['fluid'],
    characterLimit: Ember.computed.readOnly('validation.options.length.max'),
    overCharacterLimit: Ember.computed('characterLimit', 'value.length', function () {
      return this.get('characterLimit') < this.get('value.length');
    })
  });

  _exports.default = _default;
});