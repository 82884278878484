define("ember-nrg-ui/application/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(Ember.Evented, {
    name: '',
    roles: Ember.computed(function () {
      return [];
    })
  });

  _exports.default = _default;
});