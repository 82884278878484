define("ember-nrg-ui/components/nrg-text-field/trim-input/component", ["exports", "ember-nrg-ui/components/nrg-text-field/trim-input/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    _value: '',
    value: Ember.computed('_value', {
      get() {
        return this.get('_value').trim();
      },

      set(key, value) {
        const oldValue = this.get('value');
        let newValue = '';

        if (value && typeof value == 'string') {
          newValue = value.trim() || '';
        } else if (value && typeof value == 'number') {
          newValue = value.toString() || '';
        }

        if (oldValue !== newValue) {
          this.set('_value', newValue);
        }
      }

    }),

    onChange() {},

    onFocus() {},

    onBlur() {}

  });

  _exports.default = _default;
});