define("ember-modal-dialog/templates/components/in-place-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uyemN9SH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[30,[36,0],[[32,0,[\"containerClassNamesString\"]],\" \",[32,0,[\"attachmentClass\"]],\" \",[32,0,[\"containerClass\"]]],null]],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\"]}",
    "meta": {
      "moduleName": "ember-modal-dialog/templates/components/in-place-dialog.hbs"
    }
  });

  _exports.default = _default;
});