define("ember-nrg-ui/components/nrg-modal-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JeYHmFsv",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"ui modals \",[34,6]]]],[12],[2,\"\\n\"],[6,[37,9],[[30,[36,8],[[30,[36,8],[[35,7,[\"openModals\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,5],[[30,[36,4],[[35,3],[32,1]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"div\"],[24,\"role\",\"button\"],[16,0,[31,[\"ui dimmer visible active \",[34,0]]]],[4,[38,2],[[32,0],[35,1]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[8,\"nrg-modal-container/modal-wrapper\",[],[[\"@modal\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"dimmerClass\",\"onDimmerClick\",\"action\",\"activeModal\",\"eq\",\"if\",\"_containerClass\",\"modalService\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-modal-container/template.hbs"
    }
  });

  _exports.default = _default;
});