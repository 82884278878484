define("ember-nrg-ui/components/nrg-list/default-list-item/component", ["exports", "ember-nrg-ui/components/nrg-list/default-list-item/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    image: Ember.computed.reads('item.image'),
    header: Ember.computed.reads('item.header'),
    meta: Ember.computed.reads('item.meta'),
    description: Ember.computed.reads('item.description'),
    extra: Ember.computed.reads('item.extra'),
    actions: {
      openLink(link, openLinkInNewWindow) {
        if (!link) {
          return;
        }

        if (openLinkInNewWindow) {
          const newWindow = window.open();
          newWindow.opener = null;
          newWindow.location = link;
        } else {
          window.location = link;
        }
      }

    }
  });

  _exports.default = _default;
});