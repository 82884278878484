define("ember-nrg-ui/components/nrg-new-features/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fttN2Fia",
    "block": "{\"symbols\":[],\"statements\":[[8,\"nrg-modal\",[],[[\"@isOpen\",\"@headerText\",\"@onModalClose\",\"@dismissable\",\"@primaryButton\",\"@onPrimaryButtonClick\",\"@secondaryButton\",\"@onSecondaryButtonClick\"],[[34,0],[34,1],[30,[36,3],[[32,0],[35,2]],null],false,[34,4],[30,[36,3],[[32,0],[35,5]],null],[34,6],[30,[36,3],[[32,0],[35,7]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[34,8]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"_isOpen\",\"headerText\",\"onModalClose\",\"action\",\"primaryButtonText\",\"onPrimaryClick\",\"secondaryButtonText\",\"onSecondaryClick\",\"htmlContent\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-new-features/template.hbs"
    }
  });

  _exports.default = _default;
});