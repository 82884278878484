define("ember-nrg-ui/helpers/nrg-app-version", ["exports", "ember-get-config", "ember-cli-app-version/utils/regexp"], function (_exports, _emberGetConfig, _regexp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.appVersion = appVersion;
  _exports.default = void 0;
  const {
    APP: {
      version
    }
  } = _emberGetConfig.default;

  function appVersion() {
    const parts = version.split('+');
    const isTag = parts.length === 1;
    let displayVersion = `v${version}`;

    if (!isTag) {
      displayVersion = version.match(_regexp.shaRegExp)[0];
    }

    return displayVersion;
  }

  var _default = Ember.Helper.helper(appVersion);

  _exports.default = _default;
});