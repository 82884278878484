define("ember-nrg-ui/components/nrg-sidebar-menu-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7REUN0UR",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,0,[\"sidebarAction\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"a\"],[24,6,\"\"],[16,0,[31,[[30,[36,4],[[35,0,[\"active\"]],\"active\"],null],\" item\"]]],[4,[38,3],[[32,0],[35,5],[35,0]],null],[12],[2,\"\\n    \"],[8,\"nrg-sidebar-menu-item/content\",[],[[\"@item\"],[[34,0]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[35,0,[\"sidebarURL\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"a\"],[16,6,[34,0,[\"sidebarURL\"]]],[24,0,\"item\"],[24,\"target\",\"_blank\"],[24,\"rel\",\"noopener\"],[4,[38,3],[[32,0],[35,2],[35,0]],[[\"preventDefault\"],[false]]],[12],[2,\"\\n    \"],[8,\"nrg-sidebar-menu-item/content\",[],[[\"@item\"],[[34,0]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"link-to\",[],[[\"@route\",\"@class\",\"@click\"],[[34,0,[\"routeName\"]],[34,1],[30,[36,3],[[32,0],[35,2],[35,0]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"nrg-sidebar-menu-item/content\",[],[[\"@item\"],[[34,0]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"item\",\"_linkToItemClass\",\"clickedSidebarItem\",\"action\",\"if\",\"sidebarAction\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-sidebar-menu-item/template.hbs"
    }
  });

  _exports.default = _default;
});