define("ember-nrg-ui/mixins/global-keyboard-shortcut", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    keyboardService: Ember.inject.service('keyboard-shortcut'),
    concatenatedProperties: ['keyboardShortcuts'],
    keyboardShortcuts: [],

    register() {
      this.get('keyboardService').registerKeyboardShortcuts(this);
    },

    unregister() {
      this.get('keyboardService').unregisterKeyboardShortcuts(this);
    },

    didInsertElement() {
      this._super(...arguments);

      Ember.run.scheduleOnce('afterRender', this, 'register');
    },

    willDestroyElement() {
      this._super(...arguments);

      Ember.run.scheduleOnce('afterRender', this, 'unregister');
    },

    activate() {
      this._super(...arguments);

      this.register();
    },

    deactivate() {
      this._super(...arguments);

      this.unregister();
    }

  });

  _exports.default = _default;
});