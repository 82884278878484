define("ember-nrg-ui/initializers/flash-message-timer", ["exports", "ember-cli-flash/flash/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    _object.default.reopen({
      _startHoverTime: null,

      timerTask(timeout) {
        // Allow a timeout to be specified for function override below
        timeout = timeout || Ember.get(this, 'timeout');

        if (!timeout) {
          return;
        }

        const timerTaskInstance = Ember.run.later(() => {
          this.exitMessage();
        }, timeout);
        Ember.set(this, 'timerTaskInstance', timerTaskInstance);
      },

      preventExit() {
        Ember.set(this, 'isExitable', false); // Stop timer and record when hover started

        Ember.set(this, '_startHoverTime', new Date().getTime());

        this._cancelTimer();
      },

      allowExit() {
        // Start timer back up while accounting for hover time
        const hoverTime = new Date().getTime() - Ember.get(this, '_startHoverTime');
        Ember.set(this, '_startHoverTime', null);
        Ember.set(this, 'initializedTime', this.initializedTime + hoverTime);

        if (this._getElapsedTime() < this.timeout && !this.sticky) {
          this.timerTask(this.timeout - this._getElapsedTime());
        }

        Ember.set(this, 'isExitable', true);

        this._checkIfShouldExit();
      }

    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});