define("ember-nrg-ui/components/nrg-dropdown/menu/component", ["exports", "ember-nrg-ui/components/nrg-dropdown/menu/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    subMenu: false,
    scrollable: false,
    scrollableClass: Ember.computed('scrollable', function () {
      return this.scrollable ? 'scrollable' : '';
    }),

    _onSelect() {// Implemented by dropdown
    }

  });

  _exports.default = _default;
});