define("ember-nrg-ui/components/nrg-toast/component", ["exports", "ember-cli-flash/components/flash-message", "ember-nrg-ui/components/nrg-toast/template"], function (_exports, _flashMessage, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _flashMessage.default.extend({
    layout: _template.default,
    classNames: ['floating', 'toast-box', 'compact', 'transition', 'visible'],
    flash: Ember.computed.alias('toast'),

    didInsertElement() {
      this._super(...arguments);

      this.set('_mouseEnterHandler', this._mouseEnter.bind(this));
      this.set('_mouseLeaveHandler', this._mouseLeave.bind(this));
      this.element.addEventListener('mouseenter', this._mouseEnterHandler);
      this.element.addEventListener('mouseleave', this._mouseLeaveHandler);
    },

    willDestroyElement() {
      this._super(...arguments);

      this.element.removeEventListener('mouseenter', this._mouseEnterHandler);
      this.element.removeEventListener('mouseleave', this._mouseLeaveHandler);
    },

    showProgress: Ember.computed('toast.showProgress', 'toast.sticky', 'toast.timeout', function () {
      const timeout = this.get('toast.timeout');
      const showProgress = this.get('toast.showProgress');
      const sticky = this.get('toast.sticky');
      return showProgress && timeout && !sticky;
    }),
    progressDuration: Ember.computed('toast.timeout', function () {
      const timeout = this.get('toast.timeout');

      if (!this.showProgress) {
        return;
      }

      return Ember.String.htmlSafe(`animation-duration: ${timeout}ms;`);
    })
  });

  _exports.default = _default;
});