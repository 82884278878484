define("ember-render-helpers/helpers/will-destroy", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This helper is activated immediately before the helper is un-rendered
   * (removed from the DOM). It does not run during or after initial render, or
   * when its arguments are updated.
   */
  class WillDestroyHelper extends Ember.Helper {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "fn", void 0);
      (0, _defineProperty2.default)(this, "positional", void 0);
      (0, _defineProperty2.default)(this, "named", void 0);
    }

    compute(positional, named) {
      const fn = positional[0];
      (true && !(typeof fn === 'function') && Ember.assert(`\`{{did-insert fn}}\` expects a function as the first parameter. You provided: ${fn}`, typeof fn === 'function'));
      this.fn = fn;
      this.positional = positional.slice(1);
      this.named = named;
    }

    willDestroy() {
      if (this.fn && this.positional && this.named) {
        const {
          fn
        } = this;
        fn(this.positional, this.named);
      }

      super.willDestroy();
    }

  }

  _exports.default = WillDestroyHelper;
});