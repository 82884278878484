define("ember-nrg-ui/components/nrg-dropdown/sub-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eBxWpN0Q",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,4],null,[[\"source\",\"menu\"],[[30,[36,3],[\"nrg-dropdown/source\"],null],[30,[36,3],[\"nrg-dropdown/menu\"],[[\"menuClass\",\"scrollable\",\"_onSelect\"],[[35,2],true,[30,[36,1],[[32,0],[35,0]],null]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"_onSelect\",\"action\",\"menuClass\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-dropdown/sub-menu/template.hbs"
    }
  });

  _exports.default = _default;
});