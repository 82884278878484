define("ember-nrg-ui/components/nrg-radio/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uRMhv77S",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[30,[36,2],[\"ui checkbox radio\",[30,[36,1],[[35,0],\" disabled\"],null]],null]],[12],[2,\"\\n  \"],[10,\"input\"],[14,\"tabindex\",\"0\"],[15,\"disabled\",[32,0,[\"disabled\"]]],[15,\"readonly\",[32,0,[\"readonly\"]]],[15,3,[32,0,[\"name\"]]],[15,\"checked\",[30,[36,3],[[32,0,[\"value\"]],[32,0,[\"selectedValue\"]]],null]],[15,\"onchange\",[30,[36,5],[[32,0],[32,0,[\"onClick\"]],[35,4]],null]],[14,4,\"radio\"],[12],[13],[2,\"\\n  \"],[11,\"label\"],[4,[38,5],[[32,0],[32,0,[\"onClick\"]],[35,4]],null],[12],[1,[34,6]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"if\",\"concat\",\"eq\",\"value\",\"action\",\"label\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-radio/template.hbs"
    }
  });

  _exports.default = _default;
});