define("ember-nrg-ui/application/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isTesting: Ember.computed(function () {
      const config = Ember.getOwner(this).resolveRegistration('config:environment');
      return config.environment === 'test';
    }),
    pageTitle: ''
  });

  _exports.default = _default;
});