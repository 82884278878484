define("ember-nrg-ui/context-menu/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    registeredClients: Ember.A(),
    contextItems: Ember.computed.sort('rawContextItems', 'contextItemSort'),
    contextItemSort: ['priority:desc', 'baseLabel:asc'],
    disabled: true,
    rawContextItems: Ember.computed('registeredClients.[]', 'registeredClients.@each.contextItems', function () {
      const rawContextItems = Ember.A();
      this.get('registeredClients').forEach(client => {
        client.get('contextItems').forEach(item => {
          const contextItem = Ember.Object.create(item);
          contextItem.set('client', client);
          contextItem.set('baseLabel', item.label);

          if (item.isCheckbox && item.iconClass) {
            contextItem.set('label', Ember.String.htmlSafe(`<i class='${item.iconClass} icon'></i>${item.label}`));
          }

          if (Ember.isNone(item.priority)) {
            contextItem.set('priority', 10);
          }

          rawContextItems.addObject(contextItem);
        });
      });
      return rawContextItems;
    }),

    addClient(client) {
      this.get('registeredClients').addObject(client);
    },

    removeClient(client) {
      this.get('registeredClients').removeObject(client);
    }

  });

  _exports.default = _default;
});