define("ember-nrg-ui/components/nrg-master-detail/detail/component", ["exports", "ember-nrg-ui/components/nrg-master-detail/detail/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    router: Ember.inject.service(),
    classNames: ['ui', 'segment', 'master-detail--detail'],
    baseRoute: Ember.computed('router.currentRouteName', function () {
      const currentRouteName = this.router.currentRouteName;
      const routeSegments = currentRouteName.split('.');
      routeSegments.pop();
      return routeSegments.join('.');
    }),
    shouldTakeOver: Ember.computed('router.currentRouteName', function () {
      return `${this.baseRoute}.index` !== this.router.currentRouteName;
    }),

    onBackArrowClick() {
      this.router.transitionTo(this.baseRoute);
    }

  });

  _exports.default = _default;
});