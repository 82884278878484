define("ember-nrg-ui/components/nrg-master-detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1znr76fw",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,4],null,[[\"master\",\"detail\"],[[30,[36,3],[\"nrg-master-detail/master\"],null],[30,[36,3],[\"nrg-master-detail/detail\"],[[\"onRenderStatusChange\"],[[30,[36,2],[[32,0],[30,[36,1],[[35,0]],null]],null]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isDetailVisible\",\"mut\",\"action\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-nrg-ui/components/nrg-master-detail/template.hbs"
    }
  });

  _exports.default = _default;
});