define("ember-nrg-ui/helpers/list-item-selectable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.listItemSelectable = listItemSelectable;

  function listItemSelectable(_ref) {
    let [item, isSelectable] = _ref;
    return isSelectable(item);
  }

  var _default = Ember.Helper.helper(listItemSelectable);

  _exports.default = _default;
});