define("ember-nrg-ui/components/nrg-popup/component", ["exports", "ember-nrg-ui/components/nrg-popup/template", "ember-concurrency"], function (_exports, _template, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'span',
    isOpen: false,
    hoverTimeout: 250,
    target: Ember.computed(function () {
      return this.element.querySelector('.popup-anchor');
    }),

    mouseEnter() {
      this.onMouseEnter();
    },

    mouseLeave() {
      this.onMouseLeave();
    },

    onMouseEnter() {
      this.hoverTask.perform(true);
    },

    onMouseLeave() {
      this.hoverTask.perform(false);
    },

    hoverTask: (0, _emberConcurrency.task)(function* (hovering) {
      yield (0, _emberConcurrency.timeout)(this.hoverTimeout);
      this.set('isOpen', hovering);
    }).restartable()
  });

  _exports.default = _default;
});