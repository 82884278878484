define("ember-nrg-ui/components/nrg-checkbox/component", ["exports", "ember-nrg-ui/components/nrg-checkbox/template", "ember-nrg-ui/mixins/validation"], function (_exports, _template, _validation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validation.default, {
    layout: _template.default,
    classNames: ['ui', 'checkbox'],
    classNameBindings: ['type', 'disabled', 'fitted', 'readonly:read-only'],
    readonly: false,
    checked: Ember.computed.alias('value'),
    _checked: Ember.computed.bool('checked'),
    bindValue: true,
    inputId: Ember.computed('elementId', function () {
      return this.elementId + '-input';
    }),

    handleValueChange(evt) {
      const checked = evt.target.checked;

      if (this.bindValue) {
        this.set('checked', checked);
      }

      this.onChange(checked);
    },

    onChange(checked) {
      this.sendAction('action', checked);
    }

  });

  _exports.default = _default;
});