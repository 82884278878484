define("ember-sweetalert/components/sweet-alert", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span
    aria-hidden="true"
    {{did-insert this.fire this.isOpen}}
    {{did-update this.fire this.isOpen}}
    ...attributes
  ></span>
  
  */
  {
    "id": "99xE+s8Q",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"span\"],[24,\"aria-hidden\",\"true\"],[17,1],[4,[38,0],[[32,0,[\"fire\"]],[32,0,[\"isOpen\"]]],null],[4,[38,1],[[32,0,[\"fire\"]],[32,0,[\"isOpen\"]]],null],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update\"]}",
    "meta": {
      "moduleName": "ember-sweetalert/components/sweet-alert.hbs"
    }
  });

  const CONFIGURATION = ['title', 'titleText', 'html', 'text', 'icon', 'iconColor', 'iconHtml', 'showClass', 'hideClass', 'footer', 'backdrop', 'toast', 'target', 'input', 'width', 'padding', 'background', 'position', 'grow', 'customClass', 'timer', 'timerProgressBar', 'animation', 'heightAuto', 'allowOutsideClick', 'allowEscapeKey', 'allowEnterKey', 'stopKeydownPropagation', 'keydownListenerCapture', 'showConfirmButton', 'showDenyButton', 'showCancelButton', 'confirmButtonText', 'denyButtonText', 'cancelButtonText', 'confirmButtonColor', 'denyButtonColor', 'cancelButtonColor', 'confirmButtonAriaLabel', 'denyButtonAriaLabel', 'cancelButtonAriaLabel', 'buttonsStyling', 'reverseButtons', 'focusConfirm', 'focusDeny', 'focusCancel', 'showCloseButton', 'closeButtonHtml', 'closeButtonAriaLabel', 'loaderHtml', 'showLoaderOnConfirm', 'showLoaderOnDeny', 'scrollbarPadding', 'preConfirm', 'preDeny', 'returnInputValueOnDeny', 'imageUrl', 'imageWidth', 'imageHeight', 'imageAlt', 'imageLabel', 'inputPlaceholder', 'inputValue', 'inputOptions', 'inputAutoTrim', 'inputAttributes', 'inputValidator', 'validationMessage', 'progressSteps', 'currentProgressStep', 'progressStepsDistance'];
  const EVENTS = ['willOpen', 'onBeforeOpen', // deprecated, use willOpen
  'didOpen', 'onOpen', // deprecated, use didOpen
  'didRender', 'onRender', // deprecated, use didRender
  'willClose', 'onClose', // deprecated, use willClose
  'didClose', 'onAfterClose', // deprecated, use didClose,
  'didDestroy', 'onDestroy' // deprecated, use didDestroy
  ];
  let SweetAlertComponent = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class SweetAlertComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "swal", _descriptor, this);
    }

    get isOpen() {
      if (undefined === this.args.show) {
        return true;
      }

      return this.args.show;
    }

    async fire(element, _ref2) {
      let [open] = _ref2;

      if (open) {
        let result = await this.swal.fire(this._values());

        if (result.value) {
          this._call('onConfirm', result);

          return;
        }

        this._call('onCancel', result);
      }
    }

    _call(method) {
      if (!this.isDestroying && this.args[method]) {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        this.args[method](...args);
      }
    }

    _values() {
      let props = {};
      Ember.A(CONFIGURATION).forEach(key => {
        let value = this.args[key];

        if (undefined !== value) {
          props[key] = value;
        }
      });
      Ember.A(EVENTS).forEach(key => {
        if (undefined !== this.args[key]) {
          props[key] = () => this._call(key, ...arguments);
        }
      });
      return props;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "swal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fire", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "fire"), _class.prototype)), _class));
  _exports.default = SweetAlertComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SweetAlertComponent);
});