define("ember-nrg-ui/components/nrg-list/header/search/component", ["exports", "ember-nrg-ui/components/nrg-list/header/search/template", "ember-concurrency", "ember-nrg-ui/mixins/global-keyboard-shortcut"], function (_exports, _template, _emberConcurrency, _globalKeyboardShortcut) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_globalKeyboardShortcut.default, {
    layout: _template.default,
    classNames: ['item'],
    placeholder: 'Search...',
    searchString: null,
    keyboardShortcuts: [{
      key: 'KeyS',
      actionName: 'focus',
      description: 'Focus Search Box'
    }],

    init() {
      this._super(...arguments);

      this.get('searchTask').perform(true);
    },

    searchStringObserver: Ember.observer('searchString', function () {
      this.get('searchTask').perform();
    }),
    searchTask: (0, _emberConcurrency.task)(function* (immediate) {
      const searchString = this.get('searchString');

      if (searchString === null) {
        return;
      }

      if (!immediate) {
        yield (0, _emberConcurrency.timeout)(400);
      }

      this.changed(searchString);
    }).restartable(),
    actions: {
      focus() {
        const input = this.element.querySelector('input');

        if (input) {
          input.focus();
        }
      }

    }
  });

  _exports.default = _default;
});