define("ember-nrg-ui/responsive/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    _isMobileService: Ember.inject.service('isMobile'),
    _mediaService: Ember.inject.service('media'),
    resizeService: Ember.inject.service('resize'),

    init() {
      this._super(...arguments);

      this.get('resizeService').on('didResize', this, this._handleResizeEvent);

      this._handleResizeEvent();
    },

    _handleResizeEvent() {
      const {
        innerWidth,
        innerHeight
      } = window;
      this.setProperties({
        screenWidth: innerWidth,
        screenHeight: innerHeight
      });
    },

    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,

    /* Mobile Device Checks */
    isIOS: Ember.computed.readOnly('_isMobileService.apple.device'),
    isAndroid: Ember.computed.readOnly('_isMobileService.android.device'),
    isMobileDevice: Ember.computed.or('isIOS', 'isAndroid'),

    /* Media Query Breakpoints */
    isSmallMobileScreen: Ember.computed.readOnly('_mediaService.isSmallMobile'),
    isMobileScreen: Ember.computed.readOnly('_mediaService.isMobile'),
    isTabletScreen: Ember.computed.readOnly('_mediaService.isTablet'),
    isComputerScreen: Ember.computed.readOnly('_mediaService.isComputer'),
    isLargeMonitor: Ember.computed.readOnly('_mediaService.isLargeMonitor'),
    isWidescreenMonitor: Ember.computed.readOnly('_mediaService.isWidescreenMonitor'),

    /* Media Query Computed Groups */
    isMobileScreenGroup: Ember.computed.or('isSmallMobileScreen', 'isMobileScreen', 'isTabletScreen'),
    isComputerScreenGroup: Ember.computed.or('isComputerScreen', 'isLargeMonitor', 'isWidescreenMonitor')
  });

  _exports.default = _default;
});