define("@glimmer/reference", ["exports", "@glimmer/util", "@glimmer/validator", "@glimmer/global-context"], function (_exports, _util, _validator, _globalContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isModified = isModified;
  _exports.IterationItemReference = _exports.PropertyReference = _exports.HelperRootReference = _exports.ComponentRootReference = _exports.RootReference = _exports.UPDATE_REFERENCED_VALUE = _exports.IterableReference = _exports.UNDEFINED_REFERENCE = _exports.ConstReference = _exports.ReferenceCache = _exports.CachedReference = void 0;

  class CachedReference {
    constructor() {
      this.tag = null;
      this.lastRevision = null;
      this.lastValue = null;
    }

    value() {
      var {
        lastRevision,
        tag
      } = this;
      var lastValue;

      if (tag === null || !(0, _validator.validateTag)(tag, lastRevision)) {
        tag = this.tag = (0, _validator.track)(() => {
          lastValue = this.lastValue = this.compute();
        }, true
        /* DEBUG */
        && this.debugLabel);
        this.lastRevision = (0, _validator.valueForTag)(tag);
      } else {
        lastValue = this.lastValue;
      }

      (0, _validator.consumeTag)(tag);
      return lastValue;
    }

    isConst() {
      if (true
      /* DEBUG */
      && this.tag === null) {
        throw new Error('Attempted to read isConst before the reference was calculated for the first time');
      }

      return this.tag === _validator.CONSTANT_TAG;
    }

  } //////////


  _exports.CachedReference = CachedReference;

  class ReferenceCache {
    constructor(reference) {
      this.reference = reference;
      this.lastValue = reference.value();
    }

    revalidate() {
      var {
        lastValue
      } = this;
      var currentValue = this.reference.value();
      if (currentValue === lastValue) return NOT_MODIFIED;
      this.lastValue = currentValue;
      return currentValue;
    }

  }

  _exports.ReferenceCache = ReferenceCache;
  var NOT_MODIFIED = (0, _util.symbol)('NOT_MODIFIED');

  function isModified(value) {
    return value !== NOT_MODIFIED;
  }

  class PrimitiveReference {
    constructor(inner) {
      this.inner = inner;
    }

    value() {
      return this.inner;
    }

    isConst() {
      return true;
    }

    get(_key) {
      return UNDEFINED_REFERENCE;
    }

  }

  var UNDEFINED_REFERENCE = new PrimitiveReference(undefined);
  _exports.UNDEFINED_REFERENCE = UNDEFINED_REFERENCE;

  class ConstReference {
    constructor(inner) {
      this.inner = inner;
    }

    value() {
      return this.inner;
    }

    isConst() {
      return true;
    }

    get(_key) {
      return UNDEFINED_REFERENCE;
    }

  }

  _exports.ConstReference = ConstReference;
  var UPDATE_REFERENCED_VALUE = (0, _util.symbol)('UPDATE_REFERENCED_VALUE');
  /**
   * RootReferences refer to a constant root value within a template. For
   * instance, the `this` in `{{this.some.prop}}`. This is typically a:
   *
   * - Component
   * - Controller
   * - Helper
   *
   * Or another "top level" template construct, if you will. PropertyReferences
   * chain off a root reference in the template, and can then be passed around and
   * used at will.
   */

  _exports.UPDATE_REFERENCED_VALUE = UPDATE_REFERENCED_VALUE;

  class RootReference extends CachedReference {
    constructor() {
      super(...arguments);
      this.children = (0, _util.dict)();
    }

    get(key) {
      // References should in general be identical to one another, so we can usually
      // deduplicate them in production. However, in DEBUG we need unique references
      // so we can properly key off them for the logging context.
      if (true
      /* DEBUG */
      ) {
        return new PropertyReference(this, key);
      } else {
        var ref = this.children[key];

        if (ref === undefined) {
          ref = this.children[key] = new PropertyReference(this, key);
        }

        return ref;
      }
    }

  }

  _exports.RootReference = RootReference;

  class ComponentRootReference extends RootReference {
    constructor(inner) {
      super();
      this.inner = inner;

      if (true
      /* DEBUG */
      ) {
        this.debugLabel = 'this';
      }
    }

    value() {
      return this.inner;
    }

    isConst() {
      return true;
    } // Make type checker happy...


    compute() {
      return this.inner;
    }

  }

  _exports.ComponentRootReference = ComponentRootReference;

  class HelperRootReference extends RootReference {
    constructor(fn, args, debugName) {
      super();

      if (true
      /* DEBUG */
      ) {
        var name = debugName || fn.name;
        this.debugLabel = `(result of a \`${name}\` helper)`;
      }

      this.compute = fn.bind(null, args);
    }

  }
  /**
   * PropertyReferences represent a property that has been accessed on a root, or
   * another property (or iterable, see below). `some` and `prop` in
   * `{{this.some.prop}}` are each property references, `some` being a property of
   * `this`, and `prop` being a property of `some`. They are constructed by
   * recursively calling `get` on the previous reference as a template chain is
   * followed.
   */


  _exports.HelperRootReference = HelperRootReference;

  class PropertyReference extends CachedReference {
    constructor(parentReference, propertyKey) {
      super();
      this.parentReference = parentReference;
      this.propertyKey = propertyKey;
      this.children = (0, _util.dict)();

      if (true
      /* DEBUG */
      ) {
        this.debugLabel = `${parentReference.debugLabel}.${propertyKey}`;
      }
    }

    compute() {
      var {
        parentReference,
        propertyKey
      } = this;
      var parentValue = parentReference.value();

      if ((0, _util.isDict)(parentValue)) {
        return (0, _globalContext.getProp)(parentValue, propertyKey);
      }
    }

    get(key) {
      // References should in general be identical to one another, so we can usually
      // deduplicate them in production. However, in DEBUG we need unique references
      // so we can properly key off them for the logging context.
      if (true
      /* DEBUG */
      ) {
        return new PropertyReference(this, key);
      } else {
        var ref = this.children[key];

        if (ref === undefined) {
          ref = this.children[key] = new PropertyReference(this, key);
        }

        return ref;
      }
    }

    [UPDATE_REFERENCED_VALUE](value) {
      var {
        parentReference,
        propertyKey
      } = this;
      var parentValue = parentReference.value();

      if ((0, _util.isDict)(parentValue)) {
        (0, _globalContext.setProp)(parentValue, propertyKey, value);
      }
    }

  } //////////

  /**
   * IterationItemReferences represent an individual item in an iterable `each`.
   * They are similar to PropertyReferences, but since iteration items need to be
   * updated they have slightly different behavior. Concretely, they are the
   * `item` in:
   *
   * ```hbs
   * {{#each this.items as |item|}}
   *   {{item.foo}}
   * {{/each}}
   * ```
   *
   * Properties can chain off an iteration item, just like with the other template
   * reference types.
   */


  _exports.PropertyReference = PropertyReference;

  class IterationItemReference {
    constructor(parentReference, itemValue, itemKey) {
      this.parentReference = parentReference;
      this.itemValue = itemValue;
      this.children = (0, _util.dict)();
      this.tag = (0, _validator.createTag)();

      if (true
      /* DEBUG */
      ) {
        this.debugLabel = `${parentReference.debugLabel}.${(0, _util.debugToString)(itemKey)}`;
      }
    }

    isConst() {
      return false;
    }

    value() {
      (0, _validator.consumeTag)(this.tag);
      return this.itemValue;
    }

    update(value) {
      if (value !== this.itemValue) {
        (0, _validator.dirtyTag)(this.tag);
        this.itemValue = value;
      }
    }

    get(key) {
      // References should in general be identical to one another, so we can usually
      // deduplicate them in production. However, in DEBUG we need unique references
      // so we can properly key off them for the logging context.
      if (true
      /* DEBUG */
      ) {
        return new PropertyReference(this, key);
      } else {
        var ref = this.children[key];

        if (ref === undefined) {
          ref = this.children[key] = new PropertyReference(this, key);
        }

        return ref;
      }
    }

  }

  _exports.IterationItemReference = IterationItemReference;
  var NULL_IDENTITY = {};

  var KEY = (_, index) => index;

  var INDEX = (_, index) => String(index);

  var IDENTITY = item => {
    if (item === null) {
      // Returning null as an identity will cause failures since the iterator
      // can't tell that it's actually supposed to be null
      return NULL_IDENTITY;
    }

    return item;
  };

  function keyForPath(path) {
    if (true
    /* DEBUG */
    && path[0] === '@') {
      throw new Error(`invalid keypath: '${path}', valid keys: @index, @identity, or a path`);
    }

    return uniqueKeyFor(item => (0, _globalContext.getPath)(item, path));
  }

  function makeKeyFor(key) {
    switch (key) {
      case '@key':
        return uniqueKeyFor(KEY);

      case '@index':
        return uniqueKeyFor(INDEX);

      case '@identity':
        return uniqueKeyFor(IDENTITY);

      default:
        return keyForPath(key);
    }
  }

  class WeakMapWithPrimitives {
    get weakMap() {
      if (this._weakMap === undefined) {
        this._weakMap = new WeakMap();
      }

      return this._weakMap;
    }

    get primitiveMap() {
      if (this._primitiveMap === undefined) {
        this._primitiveMap = new Map();
      }

      return this._primitiveMap;
    }

    set(key, value) {
      if ((0, _util.isObject)(key) || typeof key === 'function') {
        this.weakMap.set(key, value);
      } else {
        this.primitiveMap.set(key, value);
      }
    }

    get(key) {
      if ((0, _util.isObject)(key) || typeof key === 'function') {
        return this.weakMap.get(key);
      } else {
        return this.primitiveMap.get(key);
      }
    }

  }

  var IDENTITIES = new WeakMapWithPrimitives();

  function identityForNthOccurence(value, count) {
    var identities = IDENTITIES.get(value);

    if (identities === undefined) {
      identities = [];
      IDENTITIES.set(value, identities);
    }

    var identity = identities[count];

    if (identity === undefined) {
      identity = {
        value,
        count
      };
      identities[count] = identity;
    }

    return identity;
  }
  /**
   * When iterating over a list, it's possible that an item with the same unique
   * key could be encountered twice:
   *
   * ```js
   * let arr = ['same', 'different', 'same', 'same'];
   * ```
   *
   * In general, we want to treat these items as _unique within the list_. To do
   * this, we track the occurences of every item as we iterate the list, and when
   * an item occurs more than once, we generate a new unique key just for that
   * item, and that occurence within the list. The next time we iterate the list,
   * and encounter an item for the nth time, we can get the _same_ key, and let
   * Glimmer know that it should reuse the DOM for the previous nth occurence.
   */


  function uniqueKeyFor(keyFor) {
    var seen = new WeakMapWithPrimitives();
    return (value, memo) => {
      var key = keyFor(value, memo);
      var count = seen.get(key) || 0;
      seen.set(key, count + 1);

      if (count === 0) {
        return key;
      }

      return identityForNthOccurence(key, count);
    };
  }

  class IterableReference extends CachedReference {
    constructor(parentRef, key) {
      super();
      this.parentRef = parentRef;
      this.key = key;
      this.iterator = null;
    }

    isConst() {
      return false;
    }

    isDone() {
      return this.iterator === null;
    }

    compute() {
      return !this.isEmpty();
    }

    isEmpty() {
      var iterator = this.iterator = this.createIterator();
      return iterator.isEmpty();
    }

    next() {
      var iterator = this.iterator;
      var item = iterator.next();

      if (item === null) {
        this.iterator = null;
      }

      return item;
    }

    createIterator() {
      var {
        parentRef,
        key
      } = this;
      var iterable = parentRef.value();
      var keyFor = makeKeyFor(key);

      if (Array.isArray(iterable)) {
        return new ArrayIterator(iterable, keyFor);
      }

      var maybeIterator = (0, _globalContext.toIterator)(iterable);

      if (maybeIterator === null) {
        return new ArrayIterator(_util.EMPTY_ARRAY, () => null);
      }

      return new IteratorWrapper(maybeIterator, keyFor);
    }

    childRefFor(key, value) {
      var {
        parentRef
      } = this;
      return new IterationItemReference(parentRef, value, true
      /* DEBUG */
      ? `(key: ${(0, _util.debugToString)(key)}` : '');
    }

  }

  _exports.IterableReference = IterableReference;

  class IteratorWrapper {
    constructor(inner, keyFor) {
      this.inner = inner;
      this.keyFor = keyFor;
    }

    isEmpty() {
      return this.inner.isEmpty();
    }

    next() {
      var nextValue = this.inner.next();

      if (nextValue !== null) {
        nextValue.key = this.keyFor(nextValue.value, nextValue.memo);
      }

      return nextValue;
    }

  }

  class ArrayIterator {
    constructor(iterator, keyFor) {
      this.iterator = iterator;
      this.keyFor = keyFor;
      this.pos = 0;

      if (iterator.length === 0) {
        this.current = {
          kind: 'empty'
        };
      } else {
        this.current = {
          kind: 'first',
          value: iterator[this.pos]
        };
      }
    }

    isEmpty() {
      return this.current.kind === 'empty';
    }

    next() {
      var value;
      var current = this.current;

      if (current.kind === 'first') {
        this.current = {
          kind: 'progress'
        };
        value = current.value;
      } else if (this.pos >= this.iterator.length - 1) {
        return null;
      } else {
        value = this.iterator[++this.pos];
      }

      var {
        keyFor
      } = this;
      var key = keyFor(value, this.pos);
      var memo = this.pos;
      return {
        key,
        value,
        memo
      };
    }

  }
});