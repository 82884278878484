define("ember-nrg-ui/mixins/route-page-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    applicationService: Ember.inject.service('application'),
    pageTitle: '',
    actions: {
      didTransition() {
        this._super(...arguments);

        if (this.pageTitle) {
          this.set('applicationService.pageTitle', this.pageTitle);
        }
      }

    }
  });

  _exports.default = _default;
});