define("ember-nrg-ui/router", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(router) {
    router.route('release-notes');
    router.route('not-found', {
      path: '/*path'
    });
  }
});