define("ember-nrg-ui/components/nrg-list/header/filter/component", ["exports", "ember-nrg-ui/components/nrg-list/header/filter/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['right', 'item'],
    defaultText: 'Select a Filter',
    direction: 'down',
    dropdownClass: 'floating',
    menuDirection: 'left',
    selectedFilter: null,

    init() {
      this._super(...arguments);

      this.handleChangedFilter(false);
    },

    selectedObserver: Ember.observer('selectedFilter', function () {
      this.handleChangedFilter();
    }),

    handleChangedFilter() {
      let sendFalsyValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      const filterParam = this.get('filterParam');
      const selectedFilter = this.get('selectedFilter');
      const shouldSendFalsyValue = selectedFilter || sendFalsyValue;

      if (filterParam && shouldSendFalsyValue) {
        this.changed(filterParam, selectedFilter);
      }
    }

  });

  _exports.default = _default;
});